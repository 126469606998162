export const SCREEN_NAME = {
  SignUp: 'SignUp',
  Test: 'Test',
  LogIn: 'LogIn',
  Dashboard: 'Dashboard',
  CourseDetail: 'CourseDetail',
  ForgotPassword: 'ForgotPassword',
  ForgotPasswordVerification: 'ForgotPasswordVerification',
  Report: 'Report',
};

