import * as React from "react"
import Svg, { G, Circle } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={17}
      viewBox="0 0 17 17"
      {...props}
    >
      <G data-name="Ellipse 2" fill="none" stroke="#fff">
        <Circle cx={8.5} cy={8.5} r={8.5} stroke="none" />
        <Circle cx={8.5} cy={8.5} r={8} />
      </G>
    </Svg>
  )
}

export default SvgComponent
