const Route = require('route-parser');

class VarHelper {

  erria = async (callback) => {
    try {
      const data = await callback();
      return [data, null];
    } catch(err) {
      return [null, err];
    }
  }

  toSlug(str) {
    if (!str) return '_';
    return str.replace(/[^a-zA-Z ]/g, "").replace(/\s/g, '-').toLowerCase();
  }

  parseRoute = (str : string, route: string | { path: string }) : { isValid: boolean, params?: any } => {
    // const r = new Route(route);
    const routeUri = typeof route === 'string' ? route : route.path;
    const parse = new Route(routeUri).match(str);
    if (!parse) return { isValid: false, params: {} };
    return { isValid: true, params: parse };
  }

  compareId = (id1, id2) => {
    // string or number
    return id1 == id2;
  }

  imgApiStringifyGetOne = (stringifyValue) => {
    try {
      const data = JSON.parse(stringifyValue);
      return data[0].url;
    } catch(err) {
      return '';
    }
  }

  imgApiStringifyGetAll = (stringifyValue) => {
    if (!stringifyValue) return [];
    try {
      const data = JSON.parse(stringifyValue);
      return data.map(val => val.url);
    } catch(err) {
      return [];
    }
  }

};

export default new VarHelper();