import * as React from "react"
import Svg, { G, Circle, Path, Rect } from "react-native-svg"

function SvgComponent(props) {
  const [fillColor, setFillColor] = React.useState(props.fill || '#fff');
  return (
    <Svg
      data-name="Component 2 \u2013 1"
      xmlns="http://www.w3.org/2000/svg"
      width={226}
      height={226}
      viewBox="0 0 226 226"
      onMouseEnter={props.hoverFill ? () => setFillColor(props.hoverFill) : undefined}
      onMouseLeave={props.hoverFill ? () => setFillColor(props.fill || '#fff') : undefined}
      {...props}
    >
      <G data-name="Ellipse 4" fill={fillColor === "#fff" ? "none" : '#fff'} stroke="#fff" strokeWidth={2}>
        <Circle cx={113} cy={113} r={113} stroke="none" />
        <Circle cx={113} cy={113} r={112} />
      </G>
      <G data-name="Group 46" fill="none" stroke={fillColor}>
        <G data-name="Group 43" transform="translate(52 57)">
          <Path
            data-name="Path 6"
            d="M116.199 0H5.534A5.54 5.54 0 000 5.534v76.082a5.54 5.54 0 005.534 5.532h39.425v8.3a11.554 11.554 0 01-1.57 5.185l-3.081 4.62a3.722 3.722 0 003.269 6.1H78.16a3.722 3.722 0 003.267-6.1l-3.081-4.62a11.577 11.577 0 01-1.569-5.185v-8.3h39.424a5.54 5.54 0 005.532-5.532V5.534A5.54 5.54 0 00116.199 0z"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.001}
          />
          <Circle
            data-name="Ellipse 11"
            cx={3.458}
            cy={3.458}
            r={3.458}
            transform="translate(57.408 74.699)"
          />
          <Path
            data-name="Line 1"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.001}
            d="M.002 67.541h121.732"
          />
          <Path
            data-name="Line 2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.001}
            d="M9.619 87.148h99.598"
          />
        </G>
        <G
          data-name="Group 45"
          transform="translate(75.336 70.349)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.001}
        >
          <G data-name="Group 44" transform="translate(19.063 2.208)">
            <Circle data-name="Ellipse 12" cx={18.469} cy={18.469} r={18.469} />
            <Path
              data-name="Path 7"
              d="M21.665 1.901l-5.2 7.811a5.63 5.63 0 00-.421 5.49l.855 1.842a5.009 5.009 0 005.255 2.847l1.622-.232a2.966 2.966 0 013.178 4.035l-4.79 12"
            />
            <Path
              data-name="Path 8"
              d="M.122 20.555l4.594.995a4.593 4.593 0 013.3 6.175l-1.792 4.539"
            />
          </G>
          <Rect
            data-name="Rectangle 20"
            width={75.062}
            height={40.94}
            rx={2.835}
          />
        </G>
      </G>
    </Svg>
  )
}

export default SvgComponent
