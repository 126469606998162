import React from 'react';
import { Text } from 'react-quick-style-components';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import {
  SignUp,
  Test,
  LogIn,
  Dashboard,
  CourseDetail,
  ForgotPassword,
  ForgotPasswordVerification,
  Report,
} from 'screens';
import { SCREEN_NAME } from 'const';

const Stack = createStackNavigator();

interface IStackAllProps {
  initialParams?: {
    [screenName: string]: any,
  },
  initialRouteName?: string,
  [anyAdditionProps: string]: any,
}

const StackAll = (props : IStackAllProps) => {
  return (
    <Stack.Navigator screenOptions={{ header: () => null }} {...props}>
      <Stack.Screen name={SCREEN_NAME.SignUp} component={SignUp} options={{ title: 'Home' }} />
      <Stack.Screen name={SCREEN_NAME.Test} component={Test} options={{ title: 'Test' }} />
      <Stack.Screen name={SCREEN_NAME.LogIn} component={LogIn} options={{ title: 'Login' }} />
      <Stack.Screen name={SCREEN_NAME.Dashboard} component={Dashboard} options={{ title: 'Dashboard' }} />
      <Stack.Screen name={SCREEN_NAME.CourseDetail} component={CourseDetail} options={{ title: 'Course' }} />
      <Stack.Screen name={SCREEN_NAME.ForgotPassword} component={ForgotPassword} options={{ title: 'Forgot Password' }} />
      <Stack.Screen name={SCREEN_NAME.ForgotPasswordVerification} component={ForgotPasswordVerification} options={{ title: 'Reset Password' }} />
      <Stack.Screen name={SCREEN_NAME.Report} component={Report} options={{ title: 'Report' }} />
    </Stack.Navigator>
  );
}

export const AllStackFromHome = () => {
  return (
    <StackAll />
  )
};