import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect } from 'react';
import { VarHelper } from 'helpers';
import {
  IUserAnswers, ICourseNew, IQuizzNew
} from 'type';

import UserStore from './User.Store';

interface ISubmitFormQuiz {
  courseName: string,
  courseID: string,
  id: number,
  questionAndAnswer: Array<{ q: string, a: Array<string> }>
}

import { HOST } from './host';
import Request from './Request.Utils';

const md5 = require('md5');
// @ts-ignore
window.md5 = md5;

interface ISetters {
  setCourses?: (v : Array<ICourseNew>) => void,
  setRequiredCourses?: (v : Array<string>) => void,
  setMyAnswers?: (v : Array<IUserAnswers>) => void,
  [additionSetter: string]: (v : any) => void,
}

interface IState {
  courses: Array<ICourseNew>,
  requiredCourses: Array<string>,
  ready: boolean,
  myAnswers: Array<IUserAnswers>,
}

class Course {

  constructor() {
    // this.getAllContent();
  }

  state : IState = {
    courses: [],
    requiredCourses: [],
    myAnswers: [],
    ready: false,
  };

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  createStore() {
    const [courses, setCourses] = useGlobalState<Array<ICourseNew>>(this.state.courses, 'course_store_courses');
    const [requiredCourses, setRequiredCourses] = useGlobalState<Array<string>>(this.state.requiredCourses, 'course_store_requiredCourses');
    const [myAnswers, setMyAnswers] = useGlobalState<Array<IUserAnswers>>(this.state.myAnswers, 'course_store_myAnswers');
    const [ready, setReady] = useGlobalState<boolean>(this.state.ready, 'course_store_ready');

    if (!this.setters.setCourses) this.setters.setCourses = setCourses;
    if (!this.setters.setRequiredCourses) this.setters.setRequiredCourses = setRequiredCourses;
    if (!this.setters.setReady) this.setters.setReady = setReady;
    if (!this.setters.setMyAnswers) this.setters.setMyAnswers = setMyAnswers;

    useEffect(() => {
      this.updateState({ courses, requiredCourses, myAnswers });
    }, [courses, requiredCourses, myAnswers]);

    return [
      {
        courses, requiredCourses, myAnswers,
        progressInfo: this.getMyProgressInfo(),
      },
      {
        getAllCourse: this.getAllCourse,
        getMyRequiredCourses: this.getMyRequiredCourses,
        getMyAnswers: this.getMyAnswers,
        getMyProgressInfo: this.getMyProgressInfo,
        getCourseDetail: this.getCourseDetail,
        submitQuizz: this.submitQuizz,
        getReportData: this.getReportData,
        getCourseAssets: this.getCourseAssets,
      }
    ];
  }

  getAllCourse = async () => {
    return VarHelper.erria(async () => {
      const res = await Request.get(`${HOST}/courses`, {});
      if (res.data.success && res.data.data) {
        if(this.setters.setCourses) this.setters.setCourses(res.data.data);
        else this.updateState({ courses: res.data.data })
      }
      return res.data;
    });
  }

  getMyRequiredCourses = async () => {
    return VarHelper.erria(async () => {
      const res = await Request.get(`${HOST}/courses/required`, {});
      if (res.data.success && res.data.data) {
        const requiredCourses = res.data.data.split(',').map(val => val.trim());
        if(this.setters.setRequiredCourses) this.setters.setRequiredCourses(requiredCourses);
        else this.updateState({ requiredCourses: requiredCourses });
      }
    });
  }

  getMyAnswers = async () => {
    return VarHelper.erria(async () => {
      const res = await Request.get(`${HOST}/me/answers`, {});
      if (res.data.success && res.data.data) {
        if(this.setters.setMyAnswers) this.setters.setMyAnswers(res.data.data);
        else this.updateState({ myAnswers: res.data.data });
      }
    });
  }

  getMyProgressInfo = (myAnswers = this.state.myAnswers, requiredCourses = this.state.requiredCourses) => {
    const { courses } = this.state;
    const isPaymentsFundamentalsRequired = requiredCourses.includes('Payments fundamentals');
    let requiredComplete = 0;
    let requiredIncomplete = 0;
    let allComplete = 0;
    let allIncomplete = 0;
    const listCompleted = [];
    const listCompletedReport = [];

    const isPaymentsFundamentalsCounted = {
      requiredComplete: false,
      requiredIncomplete: false,
      allComplete: false,
      allIncomplete: false,
    };

    const isPaymentFundamentalsCategoryCompleted = (() => {
      const countCategoryCourse = courses.filter(c => c.Category === 'Payments fundamentals');
      return countCategoryCourse.length === countCategoryCourse.filter(c => !!myAnswers.find(m => m['Course ID'] === c['Course ID'])).length;
    })();

    if (isPaymentFundamentalsCategoryCompleted) {
      listCompletedReport.push('Payments fundamentals');
    }

    courses.forEach(val => {
      if (!val.Icons) return;
      if (val.Category === 'Payments fundamentals') {
        if (!!myAnswers.find(m => m['Course ID'] === val['Course ID'])) listCompleted.push(val['Course ID']);
        if (isPaymentFundamentalsCategoryCompleted) {
          if (!isPaymentsFundamentalsCounted.allComplete) {
            allComplete += 1;
            isPaymentsFundamentalsCounted.allComplete = true
          }
          if (isPaymentsFundamentalsRequired) {
            if (!isPaymentsFundamentalsCounted.requiredComplete) {
              requiredComplete += 1;
              isPaymentsFundamentalsCounted.requiredComplete = true;
            }
          }
        } else {
          if (!isPaymentsFundamentalsCounted.allIncomplete) {
            allIncomplete += 1;
            isPaymentsFundamentalsCounted.allIncomplete = true
          }
          if (isPaymentsFundamentalsRequired) {
            if (!isPaymentsFundamentalsCounted.requiredIncomplete) {
              requiredIncomplete += 1;
              isPaymentsFundamentalsCounted.requiredIncomplete = true;
            }
          }
        }
      } else {

        // THE REST OF THE COURSES

        if (!!myAnswers.find(m => m['Course ID'] === val['Course ID'])) {
          listCompleted.push(val['Course ID']);
          listCompletedReport.push(val['Course ID']);
          allComplete += 1;
          if (requiredCourses.includes(val.Name)) requiredComplete += 1;
        } else {
          allIncomplete += 1;
          if (requiredCourses.includes(val.Name)) requiredIncomplete += 1;
        }


      }

      
    });

    return {
      requiredComplete,
      requiredIncomplete,
      allComplete,
      allIncomplete,
      listCompleted,
      listCompletedReport,
      listRequiredCourses: requiredCourses,
    }
  
  }

  getCourseDetail = (legacyId) => {
    return VarHelper.erria(async () => {
      const res = await Request.get(`${HOST}/courses/legacy-id/${legacyId}`, {});
      if (res.data.success) {
        return res.data.data;
      }
    });
  }

  submitQuizz = async ({ courseName, courseID, id, questionAndAnswer } : ISubmitFormQuiz) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(`${HOST}/courses/submit-quizz`, {
        courseName, courseID, id, questionAndAnswer
      });
      return res.data;
    });
  }

  getReportData = async () => {
    return VarHelper.erria(async () => {
      const res = await Request.get(`${HOST}/all-users/report-data`, {});
      return res.data;
    });
  }

  getCourseAssets = async (key) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(`${HOST}/courses/assets`, { key });
      return res.data;
    });
  }
}

export default new Course();
