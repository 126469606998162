import React from 'react';
import { Col, Row, usePropsStyle, Img, Text, useDynamicResponsiveValue } from 'react-quick-style-components';
import { ICourse, ICourseNew } from 'type';
import { ICON } from 'assets';
import { fSize } from 'const';
import { VarHelper } from 'helpers';
import { useWindowDimensions } from 'react-native';
import RatioCol from './RatioCol';

interface ICourseItemProps extends ICourseNew {
  onPress?: any,
  isCompleted?: boolean,
  isRequired?: boolean,
  [anyProp: string]: any,
}

const CourseItem = (props : ICourseItemProps) => {
  const styleProps = usePropsStyle(props);
  const rV = useDynamicResponsiveValue();
  const { width } = useWindowDimensions();

  const iconSize = rV({ xs: 50, md: 80, xl: 100, xxl: width < 1920 ? 120 : 155 })
  const fontSizeTitle = rV({ xs: 14, md: 16, xl: 18, xxl: 20 })

  const icons = VarHelper.imgApiStringifyGetAll(props.Icons);

  return (
    <Row
      shadow bgWhite borderRadius10 padding20
      style={styleProps}
      onHoverStyle={{
        shadowColor: 'rgba(0,0,0,0.5)',
      }}
      useNestedHover
      onPress={props.onPress}
    >
      {Boolean(props.isRequired) && (
        <RatioCol
          ratio={68.85/60.689} width={40}
          absolute top0 right={fontSizeTitle}
        >
          <ICON.Required width="100%" height="100%" />
        </RatioCol>
      )}
      <Col width={iconSize} height={iconSize} borderRadius10 middle backgroundColor="#F6F6F6">
        {Boolean(icons.length === 0) ? (
          <ICON.ComingSoon width="100%" height="100%" />
        ) : (
          <>
            <Img source={{ uri: props.isCompleted ? icons[0] : icons[1] }} style={{ width: '50%', height: '50%' }}  />
            {/* <Img absolute top="25%" left="25%" display="none" source={{ uri: props.activeIcon }} style={{ width: '50%', height: '50%' }} onHoverStyle={{ display: 'flex' }} /> */}
          </>
        )}
      </Col>
      <Col flex1 paddingLeft20 paddingTop10 justifyContent="flex-end" height100p>
        <Col>
          <Col height45 overflow="hidden" justifyContent="flex-end" marginBottom={fSize(rV, 20)}>
            <Text
              bold colorMain
              lineHeight={fSize(rV, 25)}
              numberOfLines={2}
              fontSize={fontSizeTitle}
            >
              {props.Name}
            </Text>
          </Col>
          <Text bold color="rgba(50,50,50,0.52)" fontSize={fSize(rV, 16)} marginBottom={fSize(rV, 20)}>Video duration: {props['Video Duration']}</Text>
          <Col height6 width60 borderRadius3 backgroundColor={props.isCompleted ? '#00D864' : '#F7C727'}  />
        </Col>
      </Col>
      
    </Row>
  );
};

export default CourseItem;
