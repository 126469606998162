import * as React from "react"
import Svg, { Ellipse } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={16}
      viewBox="0 0 17 16"
      {...props}
    >
      <Ellipse
        data-name="Ellipse 1"
        cx={8.5}
        cy={8}
        rx={8.5}
        ry={8}
        fill="#fff"
      />
    </Svg>
  )
}

export default SvgComponent
