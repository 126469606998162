import { COLOR } from 'const';
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import {
  TextInput, TextInputProps,
  StyleSheet, ActivityIndicator
} from 'react-native';
import { Row, Col, Button, Text, usePropsStyle, useDynamicResponsiveValue } from 'react-quick-style-components';

import { fSize } from 'const';

interface IButtonProps {
  text: string,
  onPress: any,
  [anyProps: string]: any,
}

const White = forwardRef((props : IButtonProps, ref) => {
  const propsStyle = usePropsStyle(props);
  const rV = useDynamicResponsiveValue();
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    setLoading: v => setLoading(v),
  }));

  return (
    <Row
      onPress={loading ? undefined : props.onPress}
      bgWhite middle height={rV({ xs: 40, md: 50, xl: 60, xxl: 70 })}
      borderRadius10 borderWidth={rV({ xs: 1, xl: 2 })} borderColor="white"
      style={propsStyle}
      onHoverStyle={{
        backgroundColor: 'transparent',
      }}
      useNativeStyleProps useNestedHover
    >
      {Boolean(loading) && <ActivityIndicator size="small" color={COLOR.MAIN} style={{ marginRight: 10 }} />}
      <Text colorMain bold fontSize={fSize(rV, 22)} onHoverStyle={{ color: 'white' }}>{props.text}</Text>
    </Row>
  )
})

export default {
  White,
}