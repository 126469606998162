import React, { useState, useEffect, useRef , useCallback } from 'react';
import { Col, Img, Row, Text, useDynamicResponsiveValue, Scroll } from 'react-quick-style-components';
import { ICON, IMG } from 'assets';
import { useWindowDimensions } from 'react-native';
import { MainContentContainer, Input, Button } from 'components';
import Store from 'store';
import Svg, { Text as SvgText, TSpan } from "react-native-svg"
import { fSize, SCREEN_NAME } from 'const';
import { useNavFunc } from 'navigation';

const SignUp = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [{ user }, uApi] = Store.User.createStore();
  const { navigation } = useNavFunc();

  const rV = useDynamicResponsiveValue();

  const createAccount = useCallback(async () => {
    if (!email || !password) return alert('Please fill in your email and password');
    buttonRef.current?.setLoading(true);
    const [checkRes, checkErr] = await uApi.checkEmail(email);
    if (checkErr) {
      buttonRef.current?.setLoading(false);
      alert(String(checkErr));
      return;
    }
    if (checkRes && !checkRes.data) {
      buttonRef.current?.setLoading(false);
      alert('Your email is not approved');
      return;
    }
    const [res, err] = await uApi.createUser({ email, password });
    buttonRef.current?.setLoading(false);
    if (err) return alert(err);
    if (res) {
      console.log('res', res);
      // navigation.navigate(SCREEN_NAME.Dashboard);
    }
    
  }, [email, password])

  const buttonRef = useRef<{ setLoading: Function }>();

  useEffect(() => {
    console.log('user', user);
    if (!!user?.email) {
      navigation.navigate(SCREEN_NAME.Dashboard);
    }
  }, [user])

  return (
    <Col flex1>
      <Col absoluteFill>
        <Img source={IMG.Step3BG} style={{ width: '100%', height: '100%' }} resizeMode="cover" />
      </Col>
      <Col absoluteFill middle>
        <MainContentContainer flex1 paddingTop30>
          <ICON.AmadeusIcon style={{ marginLeft: rV({ xs: 10, lg: 0 }) }} />
        </MainContentContainer>
      </Col>
      <Col absoluteFill middle>
        <Col width={rV({ xs: '80%', md: '55%' })} aspectRatio={2/1}>
          <Svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 932 379"
            {...props}
          >
            <SvgText
              transform="translate(466 110)"
              fill="#fff"
              fontSize={120}
              fontFamily="Amadeus-Light, Amadeus"
              fontWeight={300}
            >
              <TSpan x={-236.28} y={0}>
                {"Amadeus "}
              </TSpan>
              <TSpan x={-465.84} y={120}>
                {"Payments Academy"}
              </TSpan>
              <TSpan x={-377.34} y={240}>
                {"Internal Training"}
              </TSpan>
            </SvgText>
          </Svg>
        </Col>

        <Row marginTop30 xs='100%' md='1:.' width={rV({ xs: '90%', md: '80%', lg: '70%' })}>

          <Input.InputWhiteOutline
            placeholder="Email address"
            value={email} onChange={setEmail}
            margin={7.5}
          />
          <Input.InputWhiteOutline
            placeholder="Create password"
            value={password} onChange={setPassword}
            inputProps={{ secureTextEntry: true }}
            margin={7.5}
          />
          <Button.White
            text="Create Account"
            onPress={createAccount}
            margin={7.5}
            ref={buttonRef}
          />

        </Row>
        <Row middle marginTop60 onPress={() => navigation.navigate(SCREEN_NAME.LogIn)}>
          <Text colorWhite fontSize={fSize(rV, 18)}>Already have an account? <Text bold colorWhite textDecorationLine="underline">Log-in</Text></Text>
        </Row>
      </Col>
    </Col>
  );
};

export default SignUp;