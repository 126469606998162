import * as React from "react"
import Svg, { G, Path, Text, TSpan } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={375}
      height={24}
      viewBox="0 0 375 24"
      {...props}
    >
      <G data-name="Group 48">
        <G data-name="Group 34">
          <G data-name="Group 33">
            <Path
              data-name="Line 1"
              fill="none"
              stroke="#e5166f"
              strokeLinecap="round"
              strokeWidth={12}
              d="M25 9H6"
            />
          </G>
          <Text
            data-name="The reader of this a"
            transform="translate(42)"
            fill="#444"
            fontSize={14}
            fontFamily="SourceSansPro-Bold, Source Sans Pro"
            fontWeight={700}
          >
            <TSpan x={0} y={14}>
              {"Required"}
            </TSpan>
          </Text>
        </G>
        <G data-name="Group 35">
          <G data-name="Group 33">
            <Path
              data-name="Line 1"
              fill="none"
              stroke="#00d864"
              strokeLinecap="round"
              strokeWidth={12}
              d="M151 9h-19"
            />
          </G>
          <Text
            data-name="The reader of this a"
            transform="translate(168)"
            fill="#444"
            fontSize={14}
            fontFamily="SourceSansPro-Bold, Source Sans Pro"
            fontWeight={700}
          >
            <TSpan x={0} y={14}>
              {"Completed"}
            </TSpan>
          </Text>
        </G>
        <G data-name="Group 36">
          <G data-name="Group 33">
            <Path
              data-name="Line 1"
              fill="none"
              stroke="#f7c727"
              strokeLinecap="round"
              strokeWidth={12}
              d="M287 9h-19"
            />
          </G>
          <Text
            data-name="The reader of this a"
            transform="translate(304)"
            fill="#444"
            fontSize={14}
            fontFamily="SourceSansPro-Bold, Source Sans Pro"
            fontWeight={700}
          >
            <TSpan x={0} y={14}>
              {"Incomplete"}
            </TSpan>
          </Text>
        </G>
      </G>
    </Svg>
  )
}

export default SvgComponent
