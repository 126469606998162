import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Col, Img, Row, Text, useDynamicResponsiveValue, Scroll } from 'react-quick-style-components';
import { ICON, IMG } from 'assets';
import { useWindowDimensions } from 'react-native';
import { MainContentContainer, Input, Button } from 'components';
import Store from 'store';
import Svg, { Text as SvgText, TSpan, G, } from "react-native-svg"
import { fSize, SCREEN_NAME } from 'const';
import { useNavFunc } from 'navigation';

const LogIn = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [{ user }, uApi] = Store.User.createStore();
  const { navigation } = useNavFunc();

  const rV = useDynamicResponsiveValue();

  const login = useCallback(async () => {
    if (!email || !password) return alert('Please fill in your email and password');
    buttonRef.current?.setLoading(true);
    const [res, err] = await uApi.signIn({ email, password });
    buttonRef.current?.setLoading(false);
    if (err) return alert('Login failed!');
    if (res) {
      console.log('res', res);
      navigation.navigate(SCREEN_NAME.Dashboard);
    }
  }, [email, password]);

  useEffect(() => {
    if (!!user?.email) {
      navigation.navigate(SCREEN_NAME.Dashboard);
    }
  }, [user]);

  const buttonRef = useRef<{ setLoading: Function }>();
  return (
    <Col flex1>
      <Col absoluteFill>
        <Img source={IMG.LOGIN_BG} style={{ width: '100%', height: '100%' }} resizeMode="cover" />
      </Col>
      {/* <Col absoluteFill middle>
        <MainContentContainer flex1 paddingTop30>
          <ICON.AmadeusIcon style={{ marginLeft: rV({ xs: 10, lg: 0 }) }} />
        </MainContentContainer>
      </Col> */}
      <Col absoluteFill>
        <MainContentContainer flex1 paddingTop30 alignSelf="center">
          <Col onPress={() => navigation.navigate(SCREEN_NAME.SignUp)}>
            <ICON.AmadeusIcon style={{ marginLeft: rV({ xs: 10, lg: 0 }) }} />
          </Col>
        </MainContentContainer>
        <Scroll style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Row width='80%' xs="100%" md='3:any:2' marginTop30>
            <Col padding30 aspectRatio={641 / 356}>
              <Svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox="0 0 641 356"
                {...props}
              >
                <G
                  data-name="Group 99"
                  fill="#fff"
                  fontFamily="Amadeus-Light, Amadeus"
                  fontWeight={300}
                >
                  <SvgText transform="translate(0 82)" fontSize={90}>
                    <TSpan x={0} y={0}>
                      {"\u201CAn investment in "}
                    </TSpan>
                    <TSpan x={0} y={100}>
                      {"learning pays the "}
                    </TSpan>
                    <TSpan x={0} y={200}>
                      {"best interest\u201D"}
                    </TSpan>
                  </SvgText>
                  <SvgText data-name="TItle" transform="translate(0 351)" fontSize={20}>
                    <TSpan x={0} y={0}>
                      {"\u2013 Benjamin Franklin"}
                    </TSpan>
                  </SvgText>
                </G>
              </Svg>
            </Col>
            <Col height0 width40 />
            <Col padding30 aspectRatio={533 / 394}>
              <Svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox="0 0 533 394"
                {...props}
              >
                <SvgText
                  data-name="The reader of this a"
                  fill="#fff"
                  fontSize={18}
                  fontFamily="SourceSansPro-Regular, Source Sans Pro"
                >
                  <TSpan x={0} y={18}>
                    {"At Amadeus Payments, one of our priorities is ensuring our teams "}
                  </TSpan>
                  <TSpan x={0} y={46}>
                    {"have not only the necessary level of information to perform their "}
                  </TSpan>
                  <TSpan x={0} y={74}>
                    {
                      "roles, but also the opportunity to explore all there is to know about "
                    }
                  </TSpan>
                  <TSpan x={0} y={102}>
                    {"the world of Travel Payments at Amadeus."}
                  </TSpan>
                  <TSpan x={0} y={158}>
                    {
                      "In this internal training platform, you\u2019ll have the opportunity "
                    }
                  </TSpan>
                  <TSpan x={0} y={186}>
                    {"to enhance your knowledge, learn or review key terminology, "}
                  </TSpan>
                  <TSpan x={0} y={214}>
                    {"processes and concepts, and deep dive into our products to "}
                  </TSpan>
                  <TSpan x={0} y={242}>
                    {
                      "understand how they\u2019re driving us in our mission to be travel\u2019s "
                    }
                  </TSpan>
                  <TSpan x={0} y={270}>
                    {"most trusted payments partner."}
                  </TSpan>
                  <TSpan x={0} y={326}>
                    {
                      "Once you\u2019ve logged into your unique personal training experience, "
                    }
                  </TSpan>
                  <TSpan x={0} y={354}>
                    {
                      "you\u2019ll find your mandatory courses and have a chance to browse all "
                    }
                  </TSpan>
                  <TSpan x={0} y={382}>
                    {"others that you can, at your leisure, learn from."}
                  </TSpan>
                </SvgText>
              </Svg>
            </Col>
          </Row>

          <Row marginTop30 xs='100%' md='1:.' width={rV({ xs: '90%', md: '80%', lg: '70%' })}>

            <Input.InputWhiteOutline
              placeholder="Email address"
              value={email} onChange={setEmail}
              margin={7.5}
            />
            <Input.InputWhiteOutline
              placeholder="Create password"
              value={password} onChange={setPassword}
              inputProps={{ secureTextEntry: true }}
              margin={7.5}
            />
            <Button.White
              text="Get started today"
              onPress={login}
              margin={7.5}
              ref={buttonRef}
            />

          </Row>
          <Row middle marginTop60 marginBottom30 onPress={() => navigation.navigate(SCREEN_NAME.ForgotPassword)}>
            <Text colorWhite fontSize={fSize(rV, 18)}>Forgot your password? <Text bold colorWhite textDecorationLine="underline">Reset your password</Text></Text>
          </Row>
        </Scroll>
      </Col>
    </Col>
  );
};

export default LogIn;