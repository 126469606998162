import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={72.134}
      height={72.134}
      viewBox="0 0 72.134 72.134"
      {...props}
    >
      <G data-name="Icon ionic-md-close-circle-outline">
        <Path
          data-name="Icon ionic-ios-close-circle"
          d="M36.067 0a36.067 36.067 0 1036.067 36.067A36.061 36.061 0 0036.067 0zm9.138 49.125l-9.138-9.138-9.142 9.138a2.771 2.771 0 11-3.919-3.919l9.138-9.138-9.134-9.143a2.771 2.771 0 013.915-3.915l9.138 9.138 9.138-9.138a2.772 2.772 0 013.924 3.915l-9.138 9.138 9.138 9.142a2.784 2.784 0 010 3.919 2.753 2.753 0 01-3.92.001z"
          fill="#fff"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
