import React, { useState } from 'react';
import { Col, Img, Row, useDynamicResponsiveValue, Text } from 'react-quick-style-components';
import { IMG, ICON } from 'assets';
import { RatioCol } from 'components';
import { useWindowDimensions, TextInput, StyleSheet, Platform, ActivityIndicator } from 'react-native';
import { COLOR, fSize, SCREEN_NAME } from 'const';
import { useNavFunc } from 'navigation';
import Store from 'store';

const ForgotPassword = () => {
  const { navigation } = useNavFunc();
  const [email, setEmail] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [message, setMesssage] = useState('');

  const [{ } , uApi] = Store.User.createStore();

  const { width, height } = useWindowDimensions();
  const rV = useDynamicResponsiveValue();

  const back = () => {
    if (Platform.OS === 'web') window.location.pathname = '/login';
  };

  const requestForgot = async () => {
    if (!email) return alert('Please type your email');
    setLoading(true);
    const [res, err] = await uApi.sendPasswordResetEmail(email);
    setLoading(false);
    if (err) return alert(String(err));
    setMesssage('Your request is submitted. Please check your inbox and follow the instructions there.');
  };

  const iconCloseSize = rV({ xs: 30, xl: 40, });

  return (
    <Col flex1>
      <Col absoluteFill>
        <Img source={IMG.FORGOT_PASSWORD_BG} style={{ width: '100%', height: '100%' }} resizeMode="cover" />
      </Col>
      <Col absoluteFill middle>
        <RatioCol
          shadow ratio={1190/595} width={Math.max( width * 1190/1920, 300)}
          backgroundColor="rgba(255,255,255,0.5)" borderRadius20
        >
          <Col flex1 middle>
            <Text center light colorMain fontSize={fSize(rV, 48)}>Password Reset</Text>
            <Col width={rV({ xs: '90%', md: '60%', xl: '50%' })} marginTop={rV({ xs: 15, md: 20, xxl: 35 })} paddingHorizontal={rV({ xs: 15, xxl: 30 })}>
              <Text center fontSize={fSize(rV, 18)} >{message || 'Enter the email address associated with your account and we’ll send you a link to reset your password'}</Text>
            </Col>
            <Col width={rV({ xs: '90%', md: '60%', xl: '50%' })} marginTop={rV({ xs: 15, md: 20, xxl: 35 })}>
              <Text fontSize={fSize(rV, 20)}>Email</Text>
              <TextInput
                style={[styles.input]}
                value={email}
                onChangeText={setEmail}
              />
              <Col
                middle style={styles.button} marginTop10 onPress={requestForgot}
                onHoverStyle={{
                  backgroundColor: 'transparent',
                  borderColor: COLOR.MAIN
                }}
                useNestedHover
              >
                {Boolean(loading) ? (
                  <ActivityIndicator size="small" color="white" />
                ) : (
                  <Text colorWhite bold fontSize={fSize(rV, 20)} onHoverStyle={{ color: COLOR.MAIN }}>Submit</Text>
                )}
              </Col>
            </Col>
          </Col>
          {/* CLOSE ICON */}
          <Col
            onPress={back} display={rV({ xs: 'none', md: 'flex' })}
            absolute
            top={-iconCloseSize * 1.25} right={-iconCloseSize * 1.25}
            width={-iconCloseSize} height={-iconCloseSize}
          >
            <ICON.CloseCircle width={iconCloseSize} height={iconCloseSize} />
          </Col>
        </RatioCol>
        <Row middle absolute bottom20 left="50%" transform={[{ translateX: '-50%' }]} onPress={() => navigation.navigate(SCREEN_NAME.SignUp)}>
          <Text colorWhite fontSize={fSize(rV, 18)}>Don’t have an account? <Text bold colorWhite textDecorationLine="underline">Sign Up</Text></Text>
        </Row>
      </Col>
    </Col>
  );
};

const styles = StyleSheet.create({
  input: {
    width: '100%',
    height: 50,
    borderRadius: 10,
    backgroundColor: 'white',
    fontFamily: 'Amadeus',
    borderWidth: 2,
    borderColor: '#3BA9E0',
    padding: 10,
  },
  button: {
    width: '100%',
    height: 50,
    borderRadius: 10,
    padding: 10,
    borderWidth: 2,
    borderColor: COLOR.MAIN,
    backgroundColor: COLOR.MAIN
  },
});

export default ForgotPassword;