import React from 'react';
import { Text } from 'react-quick-style-components';
import { NavigationContainer } from '@react-navigation/native';
// import { createStackNavigator } from '@react-navigation/stack';
import { linking } from './linking';
import { AllStackFromHome } from './StackAll';

// const Stack = createStackNavigator();

export default function Navigation() {
  return (
    <NavigationContainer
      linking={linking}
      documentTitle={{
        formatter: (options, route) =>
          `${options?.title ?? route?.name} - Payment Academy`,
      }}
      fallback={<Text>Loading...</Text>}
    >
      <AllStackFromHome />
    </NavigationContainer>
  );
}