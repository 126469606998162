import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"

function SvgComponent(props) {
  const [fillColor, setFillColor] = React.useState(props.fill || '#fff');
  return (
    <Svg
      data-name="Component 1 \u2013 1"
      xmlns="http://www.w3.org/2000/svg"
      width={226}
      height={226}
      viewBox="0 0 226 226"
      onMouseEnter={props.hoverFill ? () => setFillColor(props.hoverFill) : undefined}
      onMouseLeave={props.hoverFill ? () => setFillColor(props.fill || '#fff') : undefined}
      {...props}
    >
      <G data-name="Ellipse 7" fill={fillColor === "#fff" ? "none" : '#fff'} stroke="#fff" strokeWidth={2}>
        <Circle cx={113} cy={113} r={113} stroke="none" />
        <Circle cx={113} cy={113} r={112} />
      </G>
      <Path
        data-name="Path 5"
        d="M112.972 167.706l12.926 5.33c3.89 1.609 7.6-3.212 4.752-6.312-.025-.028-11.481-9.81-11.481-9.81l-.006-32 44.338 21.722a5.538 5.538 0 00-.007-8.429l-44.328-39.626V68.172a6.171 6.171 0 00-6.171-6.171h-.042a6.171 6.171 0 00-6.171 6.171v30.409l-44.333 39.625a5.538 5.538 0 00-.007 8.429l44.339-21.722-.006 32s-11.456 9.782-11.481 9.81c-2.847 3.1.862 7.921 4.752 6.312z"
        fill="none"
        stroke={fillColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.001}
      />
    </Svg>
  )
}

export default SvgComponent
