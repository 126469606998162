import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={13.503}
      height={23.619}
      viewBox="0 0 13.503 23.619"
      {...props}
    >
      <Path
        data-name="Icon ionic-ios-arrow-back"
        d="M4.07 11.806l8.937-8.93A1.688 1.688 0 0010.616.492L.491 10.606a1.685 1.685 0 00-.049 2.327l10.167 10.193A1.688 1.688 0 0013 20.742z"
        fill="#005eb8"
      />
    </Svg>
  )
}

export default SvgComponent