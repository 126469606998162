import React, { useState } from 'react';
import { Col, Row, usePropsStyle, Img, Text, useDynamicResponsiveValue } from 'react-quick-style-components';
import { ICourse, ICourseNew } from 'type';
import { ICON } from 'assets';
import { fSize, SCREEN_NAME } from 'const';
import { useWindowDimensions } from 'react-native';
import { VarHelper } from 'helpers';
import RatioCol from './RatioCol';
import CourseItem from './CourseItemNew';
import { useNavFunc } from 'navigation';

interface ICategoryItemProps {
  name: string,
  courses?: Array<ICourseNew>,
  isCompleted?: boolean,
  isRequired?: boolean,
  progressInfo?: any
  [anyProp: string]: any,
}

const CategoryItem = (props: ICategoryItemProps) => {
  // console.log('CategoryItemNew', props);
  const { navigation } = useNavFunc();
  const styleProps = usePropsStyle(props);
  const rV = useDynamicResponsiveValue();
  const { width } = useWindowDimensions();
  const [showCourse, setShowCourse] = useState(false);

  const iconSize = rV({ xs: 50, md: 80, xl: 100, xxl: width < 1920 ? 120 : 155 })
  const fontSizeTitle = rV({ xs: 14, md: 16, xl: 18, xxl: 20 });
  const firstCourse = !!props.courses && props.courses.length > 0 ? props.courses[0] : undefined;

  const renderListCourses = () => {
    return (
      <Col>
        <Col onPress={() => setShowCourse(false)}>
          <Text bold fontSize={fSize(rV, 30)} marginBottom20>← Go back</Text>
        </Col>
        <Row
          left={-10}
          right={-10}
          stretch
          responsiveLayout={{
            xs: `100%`,
            md: `50%`,
            xl: `${100 / 3}%`,
            xxl: `${100 / 3}%`,
          }}
        >
          {props.courses.map((val, i) => (
            <Col key={'item' + i}>
              <Col flex1 margin10>
                <CourseItem
                  key={'course-' + val['Course ID']}
                  {...val}
                  onPress={!val.Icons ? undefined : () => {
                    navigation.navigate(SCREEN_NAME.CourseDetail, { id: val['Course ID'], slug: VarHelper.toSlug(val['Name']) })
                  }}
                  isCompleted={props.progressInfo.listCompleted.includes(val['Course ID'])}
                />
              </Col>
            </Col>
          ))}
        </Row>
      </Col>
    );
  }

  const renderCategory = () => {
    return (
      <Row
        left={-10}
        right={-10}
        stretch
        responsiveLayout={{
          xs: `100%`,
          md: `50%`,
          xl: `${100 / 3}%`,
          xxl: `${100 / 3}%`,
        }}
      >
        <Col>
          <Col flex1 margin10>
            <Row
              shadow bgWhite borderRadius10 padding20
              style={styleProps}
              onHoverStyle={{
                shadowColor: 'rgba(0,0,0,0.5)',
              }}
              useNestedHover
              onPress={props.courses.length === 0 ? undefined : () => setShowCourse(true)}
            >
              {Boolean(props.isRequired) && (
                <RatioCol
                  ratio={68.85 / 60.689} width={40}
                  absolute top0 right={fontSizeTitle}
                >
                  <ICON.Required width="100%" height="100%" />
                </RatioCol>
              )}
              <Col width={iconSize} height={iconSize} borderRadius10 middle backgroundColor="#F6F6F6">
                {Boolean(props.courses.length === 0) ? (
                  <ICON.ComingSoon width="100%" height="100%" />
                ) : (
                  <>
                    <Img source={{ uri: props.isCompleted ? VarHelper.imgApiStringifyGetAll(firstCourse.Icons)[0] : VarHelper.imgApiStringifyGetAll(firstCourse.Icons)[1] }} style={{ width: '50%', height: '50%' }} />
                  </>
                )}
              </Col>
              <Col flex1 paddingLeft20 paddingTop10 justifyContent="flex-end" height100p>
                <Col>
                  <Col height45 overflow="hidden" justifyContent="flex-end" marginBottom={fSize(rV, 20)}>
                    <Text
                      bold colorMain
                      lineHeight={fSize(rV, 25)}
                      numberOfLines={2}
                      fontSize={fontSizeTitle}
                    >
                      {props.name}
                    </Text>
                  </Col>
                  {Boolean(props.courses.length === 0) ? null :
                    Boolean(props.courses.length === 1) ? (
                      <Text bold color="rgba(50,50,50,0.52)" fontSize={fSize(rV, 16)} marginBottom={fSize(rV, 20)}>1 Module</Text>
                    ) : (
                      <Text bold color="rgba(50,50,50,0.52)" fontSize={fSize(rV, 16)} marginBottom={fSize(rV, 20)}>{props.courses.length} Modules</Text>
                    )
                  }
                  <Col height6 width60 borderRadius3 backgroundColor={props.isCompleted ? '#00D864' : '#F7C727'} />
                </Col>
              </Col>

            </Row>
          </Col>
        </Col>
      </Row>
    )
  }

  return showCourse ? renderListCourses() : renderCategory();
};

export default CategoryItem;
