import React, { useState, useEffect } from 'react';
import { Col, Img, Row, Text, useDynamicResponsiveValue, Scroll } from 'react-quick-style-components';
import { ICON, IMG } from 'assets';
import { useWindowDimensions } from 'react-native';
import { MainContentContainer, CheckBoxRow } from 'components';
import Store from 'store';
import Svg, { Text as SvgText, TSpan } from "react-native-svg"

const Test = (props) => {
  return (
    <Col flex1 middle>
      <Text>Test</Text>
    </Col>
  )
};

export default Test;