import 'react-native-gesture-handler';
import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, Text, View, Platform } from 'react-native';
import './src/const/style';
import firebase from 'firebase';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Store from './src/store';

Sentry.init({
  dsn: "https://a3598977dd1c4b69b9f70e2cbd9b9ba7@o109340.ingest.sentry.io/5884266",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const firebaseConfig = {
  apiKey: "AIzaSyDm-WmuavKMa80z5Bapca3X98V7cbWh4-s",
  authDomain: "payment-academy.firebaseapp.com",
  projectId: "payment-academy",
  storageBucket: "payment-academy.appspot.com",
  messagingSenderId: "1023241928127",
  appId: "1:1023241928127:web:b289a12ba895fbdecaf50a",
  measurementId: "G-G3EZG0T93W"
};

firebase.initializeApp(firebaseConfig);
Store.User.init();

(() => {
  if (Platform.OS === 'web') {
    const fontCss = `

      @font-face {
        font-family: Amadeus-Bold;
        src: url("https://986840academy.com/fonts/Amadeus-Bold.otf") format("opentype");
      }
      
      @font-face {
        font-family: Amadeus;
        src: url("https://986840academy.com/fonts/Amadeus-Regular.otf") format("opentype");
      }
      
      @font-face {
        font-family: Amadeus;
        font-weight: 100;
        src: url("https://986840academy.com/fonts/Amadeus-Thin.otf") format("opentype");
      }
      
      @font-face {
        font-family: Amadeus;
        font-weight: 200;
        src: url("https://986840academy.com/fonts/Amadeus-Light.otf") format("opentype");
      }
      
      @font-face {
        font-family: Amadeus-Thin;
        font-weight: 100;
        src: url("https://986840academy.com/fonts/Amadeus-Thin.otf") format("opentype");
      }
      
      @font-face {
        font-family: Amadeus-Light;
        font-weight: 200;
        src: url("https://986840academy.com/fonts/Amadeus-Light.otf") format("opentype");
      }

      @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

      html, body {
        font-family: Amadeus;
      }
      
      .slick-vertical .slick-slide {
        border: none;
      }
    `;

    const style = document.createElement('style')
    style.textContent = fontCss;
    document.head.append(style);
  }
})();

export { default } from './src/navigation/Navigation'; 

