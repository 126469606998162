import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={7}
      height={12.244}
      viewBox="0 0 7 12.244"
      {...props}
    >
      <Path
        data-name="Icon ionic-ios-arrow-back"
        d="M4.89 6.124L.257 10.753a.875.875 0 001.239 1.236l5.249-5.245a.873.873 0 00.026-1.206L1.5.256A.875.875 0 00.261 1.492z"
        fill="#005eb8"
      />
    </Svg>
  )
}

export default SvgComponent
