import React from 'react';
import { Col } from 'react-quick-style-components';

const MainContentContainer = (props) => {
  return (
    <Col
      onResponsiveStyle={{
        xs: { width: '100%' },
        md: { width: '95%' },
        lg: { width: '85%' },
      }}
      {...props}
    />
  )
};

export default MainContentContainer;