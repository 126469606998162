import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={29.25}
      height={29.25}
      viewBox="0 0 29.25 29.25"
      {...props}
    >
      <G data-name="Icon ionic-ios-radio-button-on" fill="#005eb8">
        <Path
          data-name="Path 3"
          d="M14.625 1.969a12.651 12.651 0 11-8.951 3.705 12.573 12.573 0 018.951-3.705m0-1.969A14.625 14.625 0 1029.25 14.625 14.623 14.623 0 0014.625 0z"
        />
        <Path
          data-name="Path 4"
          d="M22.184 7.066a10.694 10.694 0 103.129 7.559 10.621 10.621 0 00-3.129-7.559z"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
