import React, { useState, useCallback, useEffect } from 'react';
import { ICourseWithQuestion, IQuizzNew } from 'type';
import { Col, Text, Row, useDynamicResponsiveValue, Scroll } from 'react-quick-style-components';
import { ICON } from 'assets';
import { RatioCol } from 'components';
import Svg, { G, Circle, Text as SvgText, TSpan } from "react-native-svg";
import { COLOR, fSize } from 'const';
import Store from 'store';

interface IQuizzProps {
  id: number,
  courseName: string,
  courseID: string,
  goBackToTraining: any,
  data: ICourseWithQuestion['QuizzesList'],
}

const Quizz = (props : IQuizzProps) => {
  // const [{}, gApi] = Store.GSheet.createStore();
  const [{ myAnswers }, cApi] = Store.Course.createStore();
  const [{ user }] = Store.User.createStore();
  const [qIndex, setQIndex] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [score, setScore] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState<Array<{ q: string, a: Array<string> }>>([]);
  const rV = useDynamicResponsiveValue();
  
  useEffect(() => {
    if (myAnswers.length === 0) {
      cApi.getMyAnswers();
    }
  }, []);

  if (props.data.length === 0) return ( <Col flex1 middle><Text>Quiz coming soon..</Text></Col> );

  const qData = props.data.sort((a,b) => a.id < b.id ? -1 : 1)[qIndex];

  const selectA = useCallback((answer, answerNumber) => {
    const newAnswers = selectedAnswers.slice();
    const findAnswerIndex = newAnswers.findIndex(val => val.q === qData.Question);
    if (qData.Type === 'Multiple choice') {
      if (findAnswerIndex === -1) {
        newAnswers.push({ q: qData.Question, a: [answerNumber] });
      } else if (newAnswers[findAnswerIndex].a.includes(answerNumber)) {
        const removeIndex = newAnswers[findAnswerIndex].a.indexOf(answerNumber);
        newAnswers[findAnswerIndex].a = [
          ...newAnswers[findAnswerIndex].a.slice(0, removeIndex),
          ...newAnswers[findAnswerIndex].a.slice(removeIndex + 1, newAnswers[findAnswerIndex].a.length),
        ];
      } else {
        newAnswers[findAnswerIndex].a.push(answerNumber);
      }
    } else {
      // only one
      if (findAnswerIndex === -1) {
        newAnswers.push({ q: qData.Question, a: [answerNumber] });
      } else {
        newAnswers[findAnswerIndex].a = [answerNumber];
      }
    }
    setSelectedAnswers(newAnswers);
  }, [qData, selectedAnswers]);

  const tryAgain = () => {
    setIsSubmitted(false);
    setQIndex(0);
  };

  const previouslyCompleted = myAnswers.find(val => val['Course ID'] === props.courseID);

  if (isSubmitted || previouslyCompleted) {
    const isPassed = score / props.data.length >= 0.8 || previouslyCompleted;
    return (
      <Col flex1 middle>
        <Text>You have submitted this quiz</Text>
        {Boolean(!previouslyCompleted) && <Text>You score: {score}/{props.data.length}</Text>}
        {Boolean(isPassed) ? (
          <>
            <Text bold colorMain fontSize20>Passed!</Text>
            <Col borderRadius10 paddingVertical5 paddingHorizontal10 marginVertical10 bgMain onPress={props.goBackToTraining}>
              <Text colorWhite>Back to training</Text>
            </Col>
          </>
        ) : (
          <>
            <Text>You need to answer correctly 80% of the questions to pass the quiz</Text>
            <Col padding5 onPress={tryAgain}>
              <Text bold colorMain>Try again</Text>
            </Col>
          </>
        )}
      </Col>
    );
  }

  const submit = async () => {
    const obj = {
      courseName: props.courseName,
      courseID: props.courseID,
      questionAndAnswer: selectedAnswers,
      id: props.id,
    };
    const [res, err] = await cApi.submitQuizz(obj);
    if (err) return alert(String(err));
    if (res.error) return alert(String(res.error));

    console.log('res.data', res.data)
    const { isPassed, score } = res.data;
    setScore(score);
    setIsSubmitted(true);
    
    // return;
    // if (checkedScore / props.data.length >= 0.8) {
    //   const [res, err] = await gApi.submitQuizzAWS({
    //     ...obj,
    //     email: user.email,
    //     name: user.displayName,
    //   });
    //   gApi.getAllContent({ force: true });
    // }

    // setIsSubmitted(true);
    // setScore(checkedScore);
  }

  const circleSize = rV({ xs: 20, md: 25 });

  const renderAnswer = (answer, answerNumber) => {
    if (!answer) return null;
    const findCurrent = selectedAnswers.find(val => val.q === qData.Question);
    const isSelected = !findCurrent ? false : findCurrent.a.includes(answerNumber);
    return (
      <Row marginBottom={rV({ xs: 10, xxl: 20 })} onPress={() => selectA(answer, answerNumber)}>
        <RatioCol
          ratio={1} width={circleSize}
          borderWidth2
          borderColor={COLOR.MAIN}
          borderRadius={circleSize / 2}
          bgWhite
        >
          <Col
            flex1 borderWidth2
            borderColor="white" 
            borderRadius={(circleSize - 4) / 2}
            bgMain={isSelected}
          />
        </RatioCol>
        <Col flex1 marginLeft={rV({ xs: 10, md: 20 })}>
          <Text fontSize={fSize(rV, 16)}>{answer}</Text>
        </Col>
      </Row>
    );
  };

  return (
    <Col flex1 padding={rV({ xs: 10, md: 15, xl: 20, xxl: 30 })}>
      <Text textAlign="center">Question <Text bold>{qIndex+1}</Text>/{props.data.length}</Text>
      <Col flex1>
        <Scroll>
          <Text marginBottom5 light colorMain fontSize={fSize(rV, 30)}>{qData.Question}</Text>
          <Text marginBottom10 light fontSize={fSize(rV, 18)}>
            {qData.Type === 'Multiple choice' ? 'You can choose multiple anwers' : 'You can choose only one answer'}
          </Text>
          {renderAnswer(qData['Answer 1'], '1')}
          {renderAnswer(qData['Answer 2'], '2')}
          {renderAnswer(qData['Answer 3'], '3')}
          {renderAnswer(qData['Answer 4'], '4')}
          {renderAnswer(qData['Answer 5'], '5')}
          {renderAnswer(qData['Answer 6'], '6')}
        </Scroll>
      </Col>
      <Row middle>
        {Boolean(qIndex !== 0) && (
          <Row width64 onPress={() => setQIndex(i => i - 1 < 0 ? props.data.length - 1 : i - 1)} middle>
            <ICON.Back widht={7} height={12.24} />
            <Text bold marginLeft10 colorMain>Back</Text>
          </Row>
        )}
        {Boolean(selectedAnswers.length === props.data.length) && (
          <Row marginLeft30 width64 middle onPress={submit}>
            <Text bold colorMain>Submit</Text>
          </Row>
        )}
        {Boolean(qIndex !== props.data.length -1) && (
          <Row width64 marginLeft30 onPress={() => setQIndex(i => i + 1 > props.data.length - 1 ? 0 : i + 1)} middle>
            <Text bold marginRight10 colorMain>Next</Text>
            <ICON.Next widht={7} height={12.24} />
          </Row>
        )}
      </Row>
    </Col>
  );
};

export const QuizzDescription = (props) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={1088}
      height={187}
      viewBox="0 0 1088 187"
      {...props}
    >
      <G data-name="Group 199">
        <G data-name="Group 111" fill="#444">
          <G
            data-name="Ellipse 18"
            transform="translate(0 64)"
            stroke="rgba(0,0,0,0)"
          >
            <Circle cx={2.5} cy={2.5} r={2.5} stroke="none" />
            <Circle cx={2.5} cy={2.5} r={2} fill="none" />
          </G>
          <SvgText
            data-name="You must watch the full video to be able to answer the quiz"
            transform="translate(13.5 55)"
            fontSize={17}
            fontFamily="SourceSansPro-Regular, Source Sans Pro"
          >
            <TSpan x={0} y={17}>
              {"You must watch the full video to be able to answer the quiz"}
            </TSpan>
          </SvgText>
          <G
            data-name="Ellipse 19"
            transform="translate(0 100)"
            stroke="rgba(0,0,0,0)"
          >
            <Circle cx={2.5} cy={2.5} r={2.5} stroke="none" />
            <Circle cx={2.5} cy={2.5} r={2} fill="none" />
          </G>
          <SvgText
            data-name="You can skip questions and go back to them later"
            transform="translate(13.5 91)"
            fontSize={17}
            fontFamily="SourceSansPro-Regular, Source Sans Pro"
          >
            <TSpan x={0} y={17}>
              {"You can skip questions and go back to them later"}
            </TSpan>
          </SvgText>
          <G
            data-name="Ellipse 20"
            transform="translate(0 136)"
            stroke="rgba(0,0,0,0)"
          >
            <Circle cx={2.5} cy={2.5} r={2.5} stroke="none" />
            <Circle cx={2.5} cy={2.5} r={2} fill="none" />
          </G>
          <SvgText
            data-name="You cannot have more than 2 wrong answers to pass the quiz (80% pass rate)"
            transform="translate(13.5 127)"
            fontSize={17}
            fontFamily="SourceSansPro-Regular, Source Sans Pro"
          >
            <TSpan x={0} y={17}>
              {
                "You cannot have more than 2 wrong answers to pass the quiz (80% pass rate)"
              }
            </TSpan>
          </SvgText>
          <G
            data-name="Ellipse 21"
            transform="translate(0 172)"
            stroke="rgba(0,0,0,0)"
          >
            <Circle cx={2.5} cy={2.5} r={2.5} stroke="none" />
            <Circle cx={2.5} cy={2.5} r={2} fill="none" />
          </G>
          <SvgText
            data-name="If you get more than 2 answers wrong, you\u2019ll need to retake the quiz. You can do this as many times as necessary."
            transform="translate(13.5 163)"
            fontSize={17}
            fontFamily="SourceSansPro-Regular, Source Sans Pro"
          >
            <TSpan x={0} y={17}>
              {
                "If you get more than 2 answers wrong, you\u2019ll need to retake the quiz. You can do this as many times as necessary."
              }
            </TSpan>
          </SvgText>
        </G>
        <SvgText
          data-name="The reader of this a"
          fill="#005eb8"
          fontSize={30}
          fontFamily="SourceSansPro-Regular, Source Sans Pro"
        >
          <TSpan x={0} y={31}>
            {"Rules of the quiz"}
          </TSpan>
        </SvgText>
      </G>
    </Svg>
  )
}

export default Quizz;