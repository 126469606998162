
import AmadeusIcon from './AmadeusIcon';
import Step3DotDemo from './Step3DotDemo';
import Uncheck from './Uncheck';
import Checked from './Checked';
import DotOutline from './DotOutline';
import DotWhite from './DotWhite';
import TravelAgency from './TravelAgency';
import Hotel from './Hotel';
import Airline from './Airline';
import Step2Overlay from './Step2Overlay';
import CourseStatusColorRow from './CourseStatusColorRow';
import Required from './Required';
import ComingSoon from './ComingSoon';
import Back from './Back';
import Next from './Next';
import CloseCircle from './CloseCircle';

const Step3BG = require('./step3_bg.png');
const Step1BG = require('./step1_bg.png');
const Step2BG = require('./step2_bg.png');
const Step3BG_TENT = require('./step3_bg_tent.png');
const Step3BG_BRIDGE = require('./step3_bg_bridge.png');
const LOGIN_BG = require('./login_bg.jpg');
const DASHBOARD_BG = require('./dashboard_bg.jpg');
const COURSE_DETAIL_BG = require('./course_detail_bg.jpg');
const FORGOT_PASSWORD_BG = require('./forgot_password_bg.jpg');

export const ICON = {
  AmadeusIcon,
  Step3DotDemo,
  Uncheck,
  Checked,
  DotOutline,
  DotWhite,
  TravelAgency,
  Hotel,
  Airline,
  Step2Overlay,
  CourseStatusColorRow,
  Required,
  ComingSoon,
  Back,
  CloseCircle,
  Next,
};

export const IMG = {
  Step3BG,
  Step1BG,
  Step2BG,
  Step3BG_TENT,
  Step3BG_BRIDGE,
  LOGIN_BG,
  DASHBOARD_BG,
  COURSE_DETAIL_BG,
  FORGOT_PASSWORD_BG,
};
