import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect } from 'react';
import { VarHelper } from 'helpers';
import { HOST } from './host';
import Request from './Request.Utils';
import GSheet from './GSheet.Store';

import firebase from 'firebase';
interface ISetters {
  setUser?: (v : any) => void,
  [additionSetter: string]: (v : any) => void,
}

class User {

  state : any = {
    ready: false,
    user: {},
  };

  init() {
    const initialUser = firebase?.auth().currentUser || {};
    !!this.setters.setUser ? this.setters.setUser(initialUser) : this.updateState({ user: initialUser });
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        !!this.setters.setUser ? this.setters.setUser(user) : this.updateState({ user });
        Request.getToken = async () => {
          const idToken = await user.getIdToken();
          return idToken;
        };
      }
      !!this.setters.setReady ? this.setters.setReady(true) : this.updateState({ ready: true });
    });
  }

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  createStore() {
    const [user, setUser] = useGlobalState(this.state.user, 'user_store_user');
    const [ready, setReady] = useGlobalState(this.state.ready, 'user_store_ready');
    if (!this.setters.setUser) this.setters.setUser = setUser;
    if (!this.setters.setReady) this.setters.setReady = setReady;
    useEffect(( ) => {
      this.updateState({ user, ready: !!user && !!user.email ? true : ready });
    }, [user, ready]);
    return [
      { user, ready },
      {
        createUser: this.createUser,
        signIn: this.signIn,
        signOut: this.signOut,
        sendPasswordResetEmail: this.sendPasswordResetEmail,
        confirmPasswordReset: this.confirmPasswordReset,
        getMyName: this.getMyName,
        checkActionCode: this.checkActionCode,
        checkEmail: this.checkEmail,
      }
    ];
  }

  createUser = async ({ email, password }) => {
    return VarHelper.erria(async () => {
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
      const { user } = userCredential;
      const displayName = this.getMyName(user);
      if (!user.displayName) {
        user.updateProfile({
          displayName,
        })
      }
      if (!!this.setters.setUser) this.setters.setUser(firebase?.auth().currentUser);
      return user;
    });
  }

  signIn = async ({ email, password }) => {
    return VarHelper.erria(async () => {
      const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
      const { user } = userCredential;
      const displayName = this.getMyName(user);
      if (!user.displayName) {
        user.updateProfile({
          displayName,
        })
      }
      if (!!this.setters.setUser) this.setters.setUser(firebase?.auth().currentUser);
      return user;
    });
  }

  signOut = async () => {
    return VarHelper.erria(async () => {
      firebase.auth().signOut();
    });
  }

  sendPasswordResetEmail = async (email) => {
    return VarHelper.erria(async () => {
      firebase.auth().sendPasswordResetEmail(email, {
        // handleCodeInApp: true,
        url: window.location.host.includes('localhost') ? 'http://localhost:19006' :
        window.location.host.includes('986840academy.com') ? 'https://training.986840academy.com' : 'https://amadeus-payment-academy.devserver.london',
      });
    });
  }

  confirmPasswordReset = async ({ code, newPassword }) => {
    return VarHelper.erria(async () => {
      firebase.auth().confirmPasswordReset(code, newPassword);
    });
  }

  getMyName = (u = this.state.user) => {
    if (GSheet.state.allUsers.length === 0) return '';
    const findUser = GSheet.state.allUsers.find(val => val.email.toLowerCase() === u?.email.toLowerCase());
    if (!findUser) return '';
    return findUser.name;
  }

  checkActionCode = (code) => {
    return VarHelper.erria(async () => {
      const res = firebase.auth().checkActionCode(code);
      return res;
    });
  }

  checkEmail = (email) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(`${HOST}/user/check-email`, { email });
      return res.data;
    });
  }
}

export default new User();
