import * as React from "react"
import Svg, { G, Ellipse, Circle } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={112}
      viewBox="0 0 17 112"
      {...props}
    >
      <G data-name="Group 32" transform="translate(-140 -501)">
        <G
          data-name="Ellipse 1"
          transform="translate(140 501)"
          fill="none"
          stroke="#fff"
        >
          <Ellipse cx={8.5} cy={8} rx={8.5} ry={8} stroke="none" />
          <Ellipse cx={8.5} cy={8} rx={8} ry={7.5} />
        </G>
        <G
          data-name="Ellipse 2"
          transform="translate(140 532)"
          fill="none"
          stroke="#fff"
        >
          <Circle cx={8.5} cy={8.5} r={8.5} stroke="none" />
          <Circle cx={8.5} cy={8.5} r={8} />
        </G>
        <Ellipse
          data-name="Ellipse 3"
          cx={8.5}
          cy={8}
          rx={8.5}
          ry={8}
          transform="translate(140 565)"
          fill="#fff"
        />
        <G
          data-name="Ellipse 9"
          transform="translate(140 596)"
          fill="none"
          stroke="#fff"
        >
          <Circle cx={8.5} cy={8.5} r={8.5} stroke="none" />
          <Circle cx={8.5} cy={8.5} r={8} />
        </G>
      </G>
    </Svg>
  )
}

export default SvgComponent
