import { initQuickStyle } from 'react-quick-style-components';
import { COLOR } from './color';

initQuickStyle.setFontStyle({
  fontFamily: 'Amadeus',
  color: COLOR.FONT,
});

initQuickStyle.setMainColor(COLOR.MAIN);

initQuickStyle.setAdditionStyles({
  bold: {
    fontFamily: 'Amadeus-Bold',
  },
  light: {
    fontFamily: 'Amadeus-Light',
  },
  center: {
    textAlign: 'center',
  },
  shadow: {
    shadowColor: 'rgba(0,0,0,0.1)',
    shadowOffset: {
      width: 1,
      height: 1
    },
    shadowOpacity: 1,
    shadowRadius: 8,
    elevation: 2,
  },
});

export const fSize = (rV, designedValue) => rV({
  xs: designedValue * 0.5,
  md: designedValue * 0.6,
  lg: designedValue * 0.6,
  xl: designedValue * 0.7,
  xxl: designedValue * 0.8,
  xxxl: designedValue,
});
