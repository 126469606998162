import * as React from "react"
import Svg, { Path, Text, TSpan } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={68.85}
      height={60.689}
      viewBox="0 0 68.85 60.689"
      {...props}
    >
      <Path
        data-name="Path 3"
        d="M68.85 0v60.689L34.425 47.182 0 60.689V0"
        fill="#e5166f"
      />
      <Text
        data-name="The reader of this a"
        transform="translate(1.768 16)"
        fill="#f5f9ff"
        fontSize={14}
        fontFamily="SourceSansPro-Bold, Source Sans Pro"
        fontWeight={700}
      >
        <TSpan x={4.685} y={14}>
          {"Required"}
        </TSpan>
      </Text>
    </Svg>
  )
}

export default SvgComponent
