import GSheet from './GSheet.Store';
import User from './User.Store';
import Course from './Course.Store';

const Store = {
  GSheet,
  User,
  Course,
};

// @ts-ignore
window.Store = Store;

export default Store;