import * as React from "react"
import Svg, { G, Rect, Text, TSpan } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={155}
      height={155}
      viewBox="0 0 155 155"
      {...props}
    >
      <G data-name="Group 165" transform="translate(-711 -1461)">
        <Rect
          data-name="IMAGE HERE"
          width={155}
          height={155}
          rx={10}
          transform="translate(711 1461)"
          fill="#d0d0d0"
          opacity={0.853}
        />
        <Text
          data-name="coming soon"
          transform="rotate(-40 2503.888 -312.378)"
          fill="#fff"
          fontSize={30}
          fontFamily="SourceSansPro-Bold, Source Sans Pro"
          fontWeight={700}
        >
          <TSpan x={-57.165} y={0}>
            {"COMING "}
          </TSpan>
          <TSpan x={-38.985} y={25}>
            {"SOON"}
          </TSpan>
        </Text>
      </G>
    </Svg>
  )
}

export default SvgComponent
