import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"

function SvgComponent(props) {
  const [fillColor, setFillColor] = React.useState(props.fill || '#fff');
  return (
    <Svg
      data-name="Component 3 \u2013 1"
      xmlns="http://www.w3.org/2000/svg"
      width={226}
      height={226}
      viewBox="0 0 226 226"
      onMouseEnter={props.hoverFill ? () => setFillColor(props.hoverFill) : undefined}
      onMouseLeave={props.hoverFill ? () => setFillColor(props.fill || '#fff') : undefined}
      {...props}
    >
      <G data-name="Ellipse 8" fill={fillColor === "#fff" ? "none" : '#fff'} stroke="#fff" strokeWidth={2}>
        <Circle cx={113} cy={113} r={113} stroke="none" />
        <Circle cx={113} cy={113} r={112} />
      </G>
      <G data-name="Group 49">
        <G
          data-name="Group 47"
          fill="none"
          stroke={fillColor}
          strokeLinecap="round"
          strokeWidth={2.001}
        >
          <Path
            data-name="Path 9"
            d="M146.634 76.192v97.166H120.46v-34.4H104.8v34.4H78.931V76.192"
            strokeLinejoin="round"
          />
          <Path
            data-name="Line 3"
            strokeLinejoin="round"
            d="M67 75.749h91.565"
          />
          <Path
            data-name="Line 4"
            strokeLinejoin="bevel"
            d="M90.164 87.953h10.987"
          />
          <Path
            data-name="Line 5"
            strokeLinejoin="bevel"
            d="M107.07 87.953h10.987"
          />
          <Path
            data-name="Line 6"
            strokeLinejoin="bevel"
            d="M123.657 87.953h10.987"
          />
          <Path
            data-name="Line 7"
            strokeLinejoin="bevel"
            d="M90.164 96.397h10.987"
          />
          <Path
            data-name="Line 8"
            strokeLinejoin="bevel"
            d="M107.07 96.397h10.987"
          />
          <Path
            data-name="Line 9"
            strokeLinejoin="bevel"
            d="M123.657 96.397h10.987"
          />
          <Path
            data-name="Line 10"
            strokeLinejoin="bevel"
            d="M90.164 104.981h10.987"
          />
          <Path
            data-name="Line 11"
            strokeLinejoin="bevel"
            d="M107.07 104.981h10.987"
          />
          <Path
            data-name="Line 12"
            strokeLinejoin="bevel"
            d="M123.657 104.981h10.987"
          />
          <Path
            data-name="Line 13"
            strokeLinejoin="bevel"
            d="M89.913 112.949H100.9"
          />
          <Path
            data-name="Line 14"
            strokeLinejoin="bevel"
            d="M106.819 112.949h10.987"
          />
          <Path
            data-name="Line 15"
            strokeLinejoin="bevel"
            d="M123.407 112.949h10.987"
          />
        </G>
        <G data-name="Group 48" fill={fillColor}>
          <Path
            data-name="Path 10"
            d="M89.57 69.755h-1.136c-.318 0-.477-.106-.477-.45v-7.882h-7.859v7.884c0 .344-.133.45-.423.45h-1.139c-.316 0-.477-.106-.477-.45V52.689c0-.369.16-.476.477-.476h1.139c.289 0 .423.106.423.476v6.829h7.859v-6.829c0-.369.159-.476.477-.476h1.136c.292 0 .424.106.424.476v16.618c0 .341-.13.448-.424.448z"
          />
          <Path
            data-name="Path 11"
            d="M100.233 69.992c-6.19 0-6.719-5.079-6.719-9.261 0-3.28.688-8.731 6.748-8.731 6.032 0 6.853 4.444 6.853 8.731-.001 4.182-.584 9.261-6.882 9.261zm0-16.192c-3.518 0-4.6 2.778-4.6 7.2 0 4.287.874 7.144 4.6 7.144 3.81 0 4.816-2.671 4.816-7.144 0-4.608-1.059-7.2-4.816-7.2z"
          />
          <Path
            data-name="Path 12"
            d="M120.134 54.116h-4.444v15.188c0 .344-.133.45-.424.45h-1.137c-.318 0-.477-.106-.477-.45V54.116h-4.419c-.343 0-.477-.133-.477-.449v-1.033c0-.289.134-.423.45-.423h10.955c.318 0 .45.134.45.423v1.033c-.001.316-.132.449-.477.449z"
          />
          <Path
            data-name="Path 13"
            d="M132.863 69.755h-9.287c-.318 0-.477-.106-.477-.423V52.661c0-.316.159-.45.477-.45h9.128c.318 0 .45.134.45.423v1.033c0 .316-.132.449-.477.449h-7.514V59.7h6.747c.318 0 .45.132.45.424v1.031c0 .317-.132.423-.45.423h-6.747v6.3h7.727c.316 0 .423.132.423.45v1.006c-.001.343-.132.421-.45.421z"
          />
          <Path
            data-name="Path 14"
            d="M146.467 69.755h-9.525c-.345 0-.478-.106-.478-.423V52.686c0-.369.159-.476.45-.476h1.166c.29 0 .423.106.423.476v15.189h7.938c.344 0 .45.106.45.45v1.006c-.002.317-.107.424-.424.424z"
          />
        </G>
      </G>
    </Svg>
  )
}

export default SvgComponent
