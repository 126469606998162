import * as React from "react"
import Svg, { Defs, G, Path, Text, TSpan, Rect } from "react-native-svg"
import { Col } from 'react-quick-style-components';
import RatioCol from "./RatioCol";
import { useWindowDimensions } from 'react-native';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

import Store from 'store'

interface IDashboardScoreProps {
  firstName?: string,
  requiredComplete?: number | string,
  requiredIncomplete?: number | string,
  allComplete?: number | string,
  allIncomplete?: number | string,
}

function DashboardScore(props : IDashboardScoreProps) {
  const { width, height } = useWindowDimensions();
  const [{ user }, uApi] = Store.User.createStore();
  const firstName = user.displayName;

  const containerHeight = 826 / 1920 * width;
  const svgHeight = Math.min(421 / 1080 * height, containerHeight);
  const svgWidth = svgHeight * 337 / 421;

  return (
    <Col absolute top0 right30 backgroundColor='rgba(0,0,0,0.4)'>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={svgWidth}
        height={svgHeight}
        viewBox="0 0 337 421"
      >
        <Defs></Defs>
        <G data-name="Group 198">
          <G filter="url(#prefix__a)">
            <Path
              data-name="Rectangle Copy 2"
              d="M7.5 7.5h322v396a10 10 0 01-10 10h-302a10 10 0 01-10-10V7.5z"
              fill="#323232"
              opacity={0.599}
            />
          </G>
          <G data-name="Group 20">
            <Text
              data-name="Welcome, [First name]"
              transform="translate(168.5 91.5)"
              fill="#fff"
              fontSize={30}
              fontFamily="Amadeus-Light, Amadeus"
              fontWeight={300}
            >
              <TSpan x={-130.125} y={0}>
                {`Welcome, ${firstName || ''}`}
              </TSpan>
            </Text>
          </G>
          <G data-name="Group 14">
            <G
              data-name="Rectangle Copy 15"
              transform="translate(30.5 115.5)"
              fill="none"
              stroke="#e5166f"
              strokeWidth={2}
            >
              <Rect width={126} height={126} rx={10} stroke="none" />
              <Rect x={1} y={1} width={124} height={124} rx={9} />
            </G>
            <Text
              data-name="Required complete"
              transform="translate(45.5 185.5)"
              fill="#fff"
              fontSize={17}
              fontFamily="SourceSansPro-Regular, Source Sans Pro"
            >
              <TSpan x={0} y={0}>
                {"Required "}
              </TSpan>
              <TSpan x={0} y={17}>
                {"complete"}
              </TSpan>
            </Text>
            <Text
              data-name={3}
              transform="translate(45.5 156.5)"
              fill="#fff"
              fontSize={31}
              fontFamily="Amadeus-Bold, Amadeus"
              fontWeight={700}
            >
              <TSpan x={0} y={0}>
                {props.requiredComplete}
              </TSpan>
            </Text>
            <Path
              data-name="Line 1"
              fill="none"
              stroke="#00d864"
              strokeLinecap="round"
              strokeWidth={6}
              d="M46 223h60"
            />
          </G>
          <G data-name="Group 49">
            <G
              data-name="Rectangle Copy 15"
              transform="translate(180.5 115.5)"
              fill="none"
              stroke="#e5166f"
              strokeWidth={2}
            >
              <Rect width={126} height={126} rx={10} stroke="none" />
              <Rect x={1} y={1} width={124} height={124} rx={9} />
            </G>
            <Text
              data-name="Required incomplete"
              transform="translate(195.5 185.5)"
              fill="#f5f9ff"
              fontSize={17}
              fontFamily="SourceSansPro-Regular, Source Sans Pro"
            >
              <TSpan x={0} y={0}>
                {"Required "}
              </TSpan>
              <TSpan fill="#fff">
                <TSpan x={0} y={17}>
                  {"incomplete"}
                </TSpan>
              </TSpan>
            </Text>
            <Text
              data-name={1}
              transform="translate(195.5 156.5)"
              fill="#fff"
              fontSize={31}
              fontFamily="Amadeus-Bold, Amadeus"
              fontWeight={700}
            >
              <TSpan x={0} y={0}>
                {props.requiredIncomplete}
              </TSpan>
            </Text>
            <Path
              data-name="Line 1"
              fill="none"
              stroke="#f7c727"
              strokeLinecap="round"
              strokeWidth={6}
              d="M196 223h60"
            />
          </G>
          <G data-name="Group 50">
            <G
              data-name="Rectangle Copy 15"
              transform="translate(30.5 264.5)"
              fill="none"
              stroke="#fff"
              strokeWidth={2}
            >
              <Rect width={126} height={126} rx={10} stroke="none" />
              <Rect x={1} y={1} width={124} height={124} rx={9} />
            </G>
            <Text
              data-name="All complete"
              transform="translate(45.5 334.5)"
              fill="#f5f9ff"
              fontSize={17}
              fontFamily="SourceSansPro-Regular, Source Sans Pro"
            >
              <TSpan x={0} y={0}>
                {"All "}
              </TSpan>
              <TSpan fill="#fff">
                <TSpan x={0} y={17}>
                  {"complete"}
                </TSpan>
              </TSpan>
            </Text>
            <Text
              data-name={3}
              transform="translate(45.5 305.5)"
              fill="#fff"
              fontSize={31}
              fontFamily="Amadeus-Bold, Amadeus"
              fontWeight={700}
            >
              <TSpan x={0} y={0}>
                {props.allComplete}
              </TSpan>
            </Text>
            <Path
              data-name="Line 1"
              fill="none"
              stroke="#00d864"
              strokeLinecap="round"
              strokeWidth={6}
              d="M46 372h60"
            />
          </G>
          <G data-name="Group 51">
            <G
              data-name="Rectangle Copy 15"
              transform="translate(180.5 264.5)"
              fill="none"
              stroke="#fff"
              strokeWidth={2}
            >
              <Rect width={126} height={126} rx={10} stroke="none" />
              <Rect x={1} y={1} width={124} height={124} rx={9} />
            </G>
            <Text
              data-name="All incomplete"
              transform="translate(195.5 334.5)"
              fill="#fff"
              fontSize={17}
              fontFamily="SourceSansPro-Regular, Source Sans Pro"
            >
              <TSpan x={0} y={0}>
                {"All "}
              </TSpan>
              <TSpan x={0} y={17}>
                {"incomplete"}
              </TSpan>
            </Text>
            <Text
              data-name={13}
              transform="translate(195.5 305.5)"
              fill="#fff"
              fontSize={31}
              fontFamily="Amadeus-Bold, Amadeus"
              fontWeight={700}
            >
              <TSpan x={0} y={0}>
                {props.allIncomplete}
              </TSpan>
            </Text>
            <Path
              data-name="Line 1"
              fill="none"
              stroke="#f7c727"
              strokeLinecap="round"
              strokeWidth={6}
              d="M196 372h60"
            />
          </G>
        </G>
      </Svg>
    </Col>
  )
}

export default DashboardScore
