import React, { useState, useEffect, useRef } from 'react';
import { useWindowDimensions, Platform, Linking } from 'react-native';
import { Col, Row, Text, useDynamicResponsiveValue, Img, Scroll } from 'react-quick-style-components';
import { MainContentContainer, RatioCol, GVideo } from 'components';
import { ICON, IMG } from 'assets';
import { useNavFunc } from 'navigation';
import { COLOR, fSize, SCREEN_NAME } from 'const';
import Store from 'store';
import { ICourseWithQuestion, ICourseNew } from 'type';
import Quizz, { QuizzDescription } from './CourseDetail.QuizzNew';
import { VarHelper } from 'helpers';

const TAB = {
  VIDEO: 'VIDEO',
  QUIZZ: 'QUIZZ',
  PRESENTATION: 'PRESENTATION'
}

const CourseDetail = () => {
  const { navigation, route, goBackOrTo, reset } = useNavFunc();
  const [{ user, ready }, uApi] = Store.User.createStore();
  const { width, height } = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const [courseData, setCourseData] = useState<ICourseWithQuestion>();
  const [videoUrl, setVideoUrl] = useState('');
  const [tab, setTab] = useState(TAB.VIDEO)
  // const [{ allQuestions, courses }, gApi] = Store.GSheet.createStore();
  const [{ }, cApi] = Store.Course.createStore();
  const rV = useDynamicResponsiveValue();
  // console.log(route);
  // @ts-ignore
  const id = route.params.id;
  // const courseData = courses.length === 0 ? undefined : courses.find(val => VarHelper.compareId(val.courseID, id));
  // const quizzData = !courseData ? [] : allQuestions.filter(val => val.name === courseData.name);
  const quizzData = !courseData ? [] : courseData.QuizzesList;

  const videoRef = useRef<HTMLVideoElement>();

  useEffect(() => {
    if (!user?.email && ready) {
      navigation.navigate(SCREEN_NAME.SignUp);
    }
    if (user?.email && ready && id) {
      cApi.getCourseDetail(id).then(async res => {
        const [data, err] = res;
        if (!!data) {
          setCourseData(data);
          const videoId = data['Video Id'];
          if (videoId) {
            const [res, err2] = await cApi.getCourseAssets(videoId);
            if (res && res.data) {
              setVideoUrl(res.data);
            }
          }
        }
        setLoading(false);
      })
    }
  }, [user, ready, id]);

  const back = () => {
    if (Platform.OS === 'web') {
      window.location.pathname = '/dashboard';
    } else {
      goBackOrTo(SCREEN_NAME.Dashboard);
    }
  }

  if (!ready || loading) return (
    <Col flex1>
      <Col absoluteFill>
        <Img source={{ uri: IMG.COURSE_DETAIL_BG }} style={{ width: '100%', height: '100%' }} />
      </Col>
      <Col absoluteFill middle>
        <Text>loading..</Text>
      </Col>
    </Col>
  )
  if (!courseData) return (
    <Col flex1>
      <Col absoluteFill>
        <Img source={{ uri: IMG.COURSE_DETAIL_BG }} style={{ width: '100%', height: '100%' }} />
      </Col>
      <Col absoluteFill middle>
        <Text>This course is coming soon..</Text>
        <Row paddingVertical10 onPress={back}>
          <ICON.Back />
          <Text marginLeft20 colorMain>Go Back</Text>
        </Row>
      </Col>
    </Col>
  );

  // console.log('courseData', courseData);

  const iconCloseSize = rV({ xs: 30, xl: 40 })

  const renderTabItem = ({ tabValue, title, style = {}, onPress = undefined, comingSoon = false }) => {
    const isActive = tab === tabValue;
    return (
      <Col
        flex1 borderRadius10 borderColor={COLOR.MAIN} borderWidth1
        height={rV({ xs: 40, lg: 60, xxl: 80 })} middle
        onHoverStyle={{ backgroundColor: COLOR.MAIN }}
        backgroundColor={isActive ? COLOR.MAIN : 'transparent'}
        useNestedHover
        style={style}
        opacity={comingSoon ? 0.7 : 1}
        onPress={!!onPress ? onPress : () => setTab(tabValue)}
      >
        <Text textAlign="center" bold fontSize={rV({ xs: 10, lg: 16, })} color={isActive ? 'white' : COLOR.MAIN} onHoverStyle={{ color: 'white' }}>{title}</Text>
      </Col>
    )
  };

  const activeIcon = VarHelper.imgApiStringifyGetAll(courseData?.Icons)?.[0];

  return (
    <Col flex1>
      <Col absoluteFill>
        <Img source={{ uri: IMG.COURSE_DETAIL_BG }} style={{ width: '100%', height: '100%' }} />
      </Col>
      <Col absoluteFill>
        <Scroll style={{ flex: 1 }}>
          <MainContentContainer padding10 paddingVertical30 alignSelf="center">
            <Row xs='100%' lg='any:1:any' alignItems="flex-start">
              {/* LEFT COLUMN */}
              <Row
                width={rV({ xs: '100%', md: 81 + 195 })} marginBottom20
                xs='1:any' lg='100%'
              >
                <Col marginBottom20>
                  <Col paddingVertical10 onPress={back}>
                    <ICON.Back />
                  </Col>
                  <Text colorMain light fontSize={fSize(rV, 30)}>{courseData.Category}</Text>
                  {Boolean(courseData['Sub Category']) && <Text bold fontSize={fSize(rV, 20)}>{courseData['Sub Category']}</Text>}
                  <Text colorMain bold fontSize={fSize(rV, 30)} marginTop20>{courseData.Name}</Text>
                </Col>
                <Col>
                  {Boolean(activeIcon) && <Img source={{ uri: activeIcon }} style={{ width: 81, height: 81, marginVertical: 20 }} />}
                </Col>
              </Row>
              {/* MIDDLE COLUMN */}
              <Col minHeight={rV({ xs: undefined, lg: height - 60 })} middle>
                <Col width={Math.max(width * 0.5, 300)}>
                  <RatioCol width={'100%'} ratio={rV({ xs: 1, lg: 16 / 9 })} bgWhite borderRadius10>
                    {Boolean(tab === TAB.VIDEO || tab === TAB.PRESENTATION) ? (
                      // <GVideo id={courseData.videoid} />
                      <video
                        src={videoUrl}
                          style={{
                          border: 'none',
                          width: '100%',
                          height: '100%',
                        }}
                        ref={videoRef}
                        controls
                        controlsList="nodownload"
                      />
                    ) : Boolean(tab === TAB.QUIZZ) ? (
                      <Quizz id={courseData.id} data={quizzData} courseID={courseData['Course ID']} courseName={courseData.Name} goBackToTraining={back} />
                    ) : null}

                    {/* CLOSE ICON */}
                    <Col
                      onPress={back} display={rV({ xs: 'none', md: 'flex' })}
                      absolute
                      top={height < 700 ? 0 : -iconCloseSize * 1.25} right={-iconCloseSize * 1.25}
                      width={-iconCloseSize} height={-iconCloseSize}
                    >
                      <ICON.CloseCircle width={iconCloseSize} height={iconCloseSize} />
                    </Col>
                  </RatioCol>
                  {/* TABS */}
                  <Row marginTop={rV({ xs: 20, xxl: 52, lg: 30 })}>
                    {renderTabItem({
                      title: 'Play video', tabValue: TAB.VIDEO,
                      onPress: () => {
                        if (tab === TAB.VIDEO && videoRef.current) {
                          videoRef.current.play();
                        } else if (tab !== TAB.VIDEO) {
                          setTab(TAB.VIDEO)
                        }
                      }
                    })}
                    {renderTabItem({
                      title: 'Download presentation',
                      style: { marginHorizontal: 20 },
                      comingSoon: !courseData['Presentation Id'],
                      onPress: async () => {
                        if (!courseData['Presentation Id']) return;
                        const [res, err] = await cApi.getCourseAssets(courseData['Presentation Id']);
                        if (res && res.data) {
                          Platform.OS === 'web' && window.open(res.data, '_blank');
                        }
                      },
                      tabValue: TAB.PRESENTATION,
                    })}
                    {renderTabItem({ title: 'Take the quiz', tabValue: TAB.QUIZZ })}
                  </Row>

                  {/* TAB CONTENT */}
                  {Boolean(tab === TAB.QUIZZ) && (
                    <RatioCol width='100%' ratio={1088 / 187} marginTop={rV({ xs: 20, xxl: 52, lg: 30 })}>
                      <QuizzDescription width='100%' height='100%' />
                    </RatioCol>
                  )}
                </Col>
              </Col>
              <Col width={rV({ xs: 30, lg: 100 })}>
              </Col>
            </Row>
          </MainContentContainer>
        </Scroll>
      </Col>
      {/* {Boolean(tab === TAB.QUIZZ) && (
        <Row absolute bottom0 left0 right0 padding10 bgMain middle>
          <MainContentContainer>
            <Row>
              <Text colorWhite>Please be aware some users have reported quiz scores incorrectly being returned as 0/10. We are working to resolve this issue as soon as possible but if you experience it please email <Text colorWhite bold onPress={() => Linking.openURL('mailto:travel.payments@amadeus.com')}>travel.payments@amadeus.com</Text>. We will keep you informed about any changes to this issue</Text>
              <Col borderRadius10 marginLeft20 bgWhite paddingVertical10 paddingHorizontal15>
                <Text colorMain>Continue</Text>
              </Col>
            </Row>
          </MainContentContainer>
        </Row>
      )} */}
    </Col>
  );
};

export default CourseDetail;
