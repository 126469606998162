import React from 'react';
import {
  TextInput, TextInputProps,
  StyleSheet,
} from 'react-native';
import { Row, Col, usePropsStyle, useDynamicResponsiveValue } from 'react-quick-style-components';

import { fSize } from 'const';

interface IInputProps {
  value: string,
  onChange(v: string): void,
  placeholder?: string,
  onEnter?: any,
  inputProps?: TextInputProps,
  [anyProps: string]: any,
}

const InputWhiteOutline = (props: IInputProps) => {
  const propsStyle = usePropsStyle(props);
  const rV = useDynamicResponsiveValue();
  return (
    <Row
      style={propsStyle}
      height={rV({ xs: 40, md: 50, xl: 60, xxl: 70 })}
      borderRadius10 borderWidth={rV({ xs: 1, xl: 2 })} borderColor="white"
    >
      <TextInput
        value={props.value}
        onChangeText={props.onChange}
        placeholder={props.placeholder}
        underlineColorAndroid="transparent"
        placeholderTextColor="white"
        style={{ fontFamily: 'Amadeus' , width: '100%', height: '100%', padding: 20, fontSize: fSize(rV, 22), color: 'white' }}
        onSubmitEditing={props.onEnter}
        textAlignVertical="top"
        {...props.inputProps}
      />
    </Row>
  );
}

const InputBlackOutline = (props: IInputProps) => {
  const propsStyle = usePropsStyle(props);
  const rV = useDynamicResponsiveValue();
  return (
    <Row
      style={propsStyle}
      height={rV({ xs: 40, md: 50, xl: 60, xxl: 70 })}
      borderRadius10 borderWidth={rV({ xs: 1, xl: 2 })} borderColor="black"
    >
      <TextInput
        value={props.value}
        onChangeText={props.onChange}
        placeholder={props.placeholder}
        underlineColorAndroid="transparent"
        placeholderTextColor="black"
        style={{ fontFamily: 'Amadeus' , width: '100%', height: '100%', padding: 20, fontSize: fSize(rV, 22), color: 'black' }}
        onSubmitEditing={props.onEnter}
        textAlignVertical="top"
        {...props.inputProps}
      />
    </Row>
  );
}

export default {
  InputWhiteOutline,
  InputBlackOutline,
};