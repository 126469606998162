import * as React from "react"
import Svg, { Defs, LinearGradient, Stop, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={1920}
      height={301}
      viewBox="0 0 1920 301"
      {...props}
    >
      <Defs>
        <LinearGradient
          id="prefix__a"
          x1={0.5}
          y1={0.035}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <Stop offset={0} stopColor="#fff" stopOpacity={0} />
          <Stop offset={1} stopColor="#0a1260" />
        </LinearGradient>
      </Defs>
      <Path
        data-name="Rectangle 23"
        fill="url(#prefix__a)"
        style={{
          mixBlendMode: "darken",
          isolation: "isolate",
        }}
        d="M0 0h1920v301H0z"
      />
    </Svg>
  )
}

export default SvgComponent
