
import { SCREEN_NAME } from 'const';

export const linking = {
  prefixes: [],
  config: {
    screens: {
      [SCREEN_NAME.SignUp]: '/',
      [SCREEN_NAME.Test]: '/test',
      [SCREEN_NAME.LogIn]: '/login',
      [SCREEN_NAME.Dashboard]: '/dashboard',
      [SCREEN_NAME.CourseDetail]: '/course/:id/:slug',
      [SCREEN_NAME.ForgotPassword]: '/forgot-password',
      [SCREEN_NAME.ForgotPasswordVerification]: '/reset-password/verification',
      [SCREEN_NAME.Report]: '/report',
    },
  }
};