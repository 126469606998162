import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect } from 'react';
import { VarHelper } from 'helpers';
import { ICourse, IQuizzQuestion, ISubmitQuizz, ISubmitFormQuiz, IUser, IGSheetUserAnswers } from 'type';

import * as Sentry from "@sentry/react";

import HARDCODED_DATA from './data.json';

const md5 = require('md5');
// @ts-ignore
window.md5 = md5;

interface ICate {
  name: string,
  isCategoryCompleted: boolean,
  isRequired: boolean,
  subCates: Array<{
    name: string,
    data: Array<ICourse>,
  }>
}

interface ISetters {
  setCourses?: (v : Array<ICourse>) => void,
  setAllQuestions?: (v : Array<IQuizzQuestion>) => void,
  setAllUsers?: (v : Array<IUser>) => void,
  setAllUserAnswers?: (v : Array<IGSheetUserAnswers>) => void,
  [additionSetter: string]: (v : any) => void,
}

interface IState {
  courses: Array<ICourse>,
  allQuestions: Array<IQuizzQuestion>,
  allUsers: Array<IUser>,
  allUserAnswers: Array<IGSheetUserAnswers>,
  ready: boolean,
}

interface SheetJSON {
  feed: {
    entry?: Array<any>,
    title: {
      $t: string,
    },
    [unimportantProp: string]: any,
  },
  version: string,
  encoding: string,
}

const SHEET_ID = '10t62XDbgNzYN0KbnML5_5nQ5rkKnVo13XhUBJWJZbKc';

class GSheet {

  constructor() {
    // this.getAllContent();
  }

  state : IState = {
    // @ts-ignore
    courses: HARDCODED_DATA.data.courses,
    // @ts-ignore
    allQuestions: HARDCODED_DATA.data.quizzes,
    allUsers: [],
    // @ts-ignore
    allUserAnswers: [],
    ready: false,
  };

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  createStore() {
    const [courses, setCourses] = useGlobalState<Array<ICourse>>(this.state.courses, 'gsheet_store_courses');
    const [allQuestions, setAllQuestions] = useGlobalState<Array<IQuizzQuestion>>(this.state.allQuestions, 'gsheet_store_allquestions');
    const [allUsers, setAllUsers] = useGlobalState<Array<IUser>>(this.state.allUsers, 'gsheet_store_allusers');
    const [allUserAnswers, setAllUserAnswers] = useGlobalState<Array<IGSheetUserAnswers>>(this.state.allUserAnswers, 'gsheet_store_alluserAnswers');
    const [ready, setReady] = useGlobalState<boolean>(this.state.ready, 'gsheet_store_ready');

    if (!this.setters.setCourses) this.setters.setCourses = setCourses;
    if (!this.setters.setAllQuestions) this.setters.setAllQuestions = setAllQuestions;
    if (!this.setters.setAllUsers) this.setters.setAllUsers = setAllUsers;
    if (!this.setters.setReady) this.setters.setReady = setReady;
    if (!this.setters.setAllUserAnswers) this.setters.setAllUserAnswers = setAllUserAnswers;

    useEffect(() => {
      this.updateState({ courses, allQuestions, allUsers, ready, allUserAnswers });
    }, [courses, allQuestions, allUsers, ready, allUserAnswers]);
    return [
      { courses, allQuestions, allUsers, ready, allUserAnswers },
      {
        getAllContent: this.getAllContent,
        submitQuizz: this.submitQuizz,
        checkScore: this.checkScore,
        getProgressInfo: this.getProgressInfo,
        getMyActiveCourses: this.getMyActiveCourses,
        submitQuizzForm: this.submitQuizzForm,
        submitQuizzAWS: this.submitQuizzAWS,
      }
    ];
  }

  getAllContent = async (options = { force: false }) => {
    return VarHelper.erria(async () => {
      const { force } = options;
      if (this.state.allUsers.length > 0 && this.state.courses.length > 0 && !force) return;
      const url = 'https://342546l17j.execute-api.us-east-1.amazonaws.com/default/payment-acad-2';
      // const url = 'https://api.readism.hanoiapp.xyz/misc/payment-academy';
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': "application/json"
        },
        body: JSON.stringify({
          type: 'get',
        }),
      })
      const json = await res.json();
      json.data?.courses?.shift();
      json.data?.quizzes?.shift();
      json.data.quizzes = json.data?.quizzes.map(val => ({
        ...val,
        type: val.type === 'Multiple choice' ? 'multiple' : 'one',
      }));
      json.data?.userAnswers?.shift();
      json.data?.users?.shift();
      console.log('json', json);
      !!this.setters.setCourses ? this.setters.setCourses(json.data.courses) : this.updateState({ courses: json.data.courses });
      !!this.setters.setAllQuestions ? this.setters.setAllQuestions(json.data.quizzes) : this.updateState({ allQuestions: json.data.quizzes });
      !!this.setters.setAllUserAnswers ? this.setters.setAllUserAnswers(json.data.userAnswers) : this.updateState({ allQuestions: json.data.userAnswers });
      !!this.setters.setAllUsers ? this.setters.setAllUsers(json.data.users) : this.updateState({ allUsers: json.data.users });
      !!this.setters.setReady ? this.setters.setReady(true) : this.updateState({ ready: true });
      return json;
    });
  }

  getAllContentOld = async (options = { force: false }) => {
    return VarHelper.erria(async () => {
      const { force } = options;
      if (this.state.allUsers.length > 0 && this.state.courses.length > 0 && !force) return;
      const tabUrl = (tabNumber) => `https://spreadsheets.google.com/feeds/list/${SHEET_ID}/${tabNumber}/public/values?alt=json`;
      const fetchJson = async (url) => {
        const res = await fetch(url);
        const json = await res.json();
        return json;
      };

      const [
        jsonTab1,
        jsonTab2,
        jsonTab3,
        jsonTab4,
      ] = await Promise.all([
        fetchJson(tabUrl(1)),
        fetchJson(tabUrl(2)),
        fetchJson(tabUrl(3)),
        fetchJson(tabUrl(4)),
      ]);

      // console.log('jsonTab1', jsonTab1);
      // console.log('jsonTab2', jsonTab2);

      this.processSheetData([jsonTab1, jsonTab2, jsonTab3, jsonTab4]);
      !!this.setters.setReady ? this.setters.setReady(true) : this.updateState({ ready: true });
    });
  }

  processSheetData = (arr : Array<SheetJSON>) => {
    const getValue = (entry, label) => entry[`gsx$${label}`]['$t'];
    arr.forEach(val => {
      if (val.feed.title.$t === '1. Courses') {
        const courses : Array<ICourse> = !val.feed.entry ? [] : val.feed.entry.map(et => {
          return {
            category: getValue(et, 'category'),
            subCategory: getValue(et, 'subcategory'),
            courseID: getValue(et, 'courseid'),
            name: getValue(et, 'name'),
            presentationUrl: !getValue(et, 'presentationid') ? '' : `https://drive.google.com/file/d/${getValue(et, 'presentationid')}/view`,
            videoid: getValue(et, 'videoid'),
            isRequired: false,
            // isRequired: getValue(et, 'isrequired') === 'Yes' ? true : false,
            activeIcon: getValue(et, 'activeicon'),
            inactiveIcon: getValue(et, 'inactiveicon'),
            videoDuration: getValue(et, 'videoduration'),
          };
        });
        !!this.setters.setCourses ? this.setters.setCourses(courses) : this.updateState({ courses });
      } else if (val.feed.title.$t === '2. Quizzes') {
        const allQuestions : Array<IQuizzQuestion> = !val.feed.entry ? [] : val.feed.entry.map(et => {
          return {
            name: getValue(et, 'name'),
            question: getValue(et, 'question'),
            answer1: getValue(et, 'answer1'),
            answer2: getValue(et, 'answer2'),
            answer3: getValue(et, 'answer3'),
            answer4: getValue(et, 'answer4'),
            answer5: getValue(et, 'answer5'),
            answer6: getValue(et, 'answer6'),
            correctAnswer: getValue(et, 'correctanswer'),
            correct: getValue(et, 'correct'),
            type: getValue(et, 'type') === 'Multiple choice' ? 'multiple' : 'one',
          };
        });
        !!this.setters.setAllQuestions ? this.setters.setAllQuestions(allQuestions) : this.updateState({ allQuestions });
      } else if (val.feed.title.$t === '3. Users') {
        const allUsers : Array<IUser> = !val.feed.entry ? [] : val.feed.entry.map(et => {
          return {
            name: getValue(et, 'name'),
            surName: getValue(et, 'surname'),
            email: getValue(et, 'email'),
            activeCourses: getValue(et, 'requiredcourses'),
          };
        });
        !!this.setters.setAllUsers ? this.setters.setAllUsers(allUsers) : this.updateState({ allUsers });
      } else if (val.feed.title.$t === '4. User Answers') {
        const allUserAnswers : Array<IGSheetUserAnswers> = !val.feed.entry ? [] : val.feed.entry.map(et => {
          return {
            name: getValue(et, 'name'),
            email: getValue(et, 'email'),
            courseID: getValue(et, 'courseid'),
            courseName: getValue(et, 'coursename'),
            answers: getValue(et, 'answers'),
          };
        });
        !!this.setters.setAllUserAnswers ? this.setters.setAllUserAnswers(allUserAnswers) : this.updateState({ allUserAnswers });
      }
    });
  }

  submitQuizzForm = async ({ courseName, courseID, questionAndAnswer, email, name } : ISubmitFormQuiz) => {
    return VarHelper.erria(() => new Promise((resolve, reject) => {
      // const url = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSe6rYvRD2Hez0rrf4hXc1SuhGEgXq5Y-vQ9YKtq3AhElYw6vA/formResponse';
      // let answerText = '';
      // questionAndAnswer.forEach(qa => {
      //   answerText += `[${qa.a}]: ${qa.q}\n`;
      // });
      // await fetch(url, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': "application/x-www-form-urlencoded;charset=utf-8"
      //   },
      //   body: `entry.1484334020=${encodeURI(email)}&entry.416914406=${encodeURI(courseName)}&entry.1891564576=${encodeURI(name)}&entry.893135435=${courseID}&entry.1255694816=${encodeURI(answerText)}`,
      // })

      let answerText = '';
      questionAndAnswer.forEach(qa => {
        answerText += `[${qa.a}]: ${qa.q}\n`;
      });

      const iframeId = ('my-response-iframe-'+Math.random()).replace('.', '');
      const formId = ('my-form-'+Math.random()).replace('.', '');
      

      const iframe = document.createElement('iframe');
      iframe.setAttribute('id', iframeId);
      iframe.setAttribute('name', iframeId);
      iframe.style.position = 'absolute';
      iframe.style.visibility = 'hidden';
      iframe.style.height = '1px';
      iframe.style.transform = 'translateX(-1000px)';

      document.body.append(iframe);
      

      const form = document.createElement('form');
      form.setAttribute('id', formId);
      form.setAttribute('method', 'post');
      form.setAttribute('action', 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSe6rYvRD2Hez0rrf4hXc1SuhGEgXq5Y-vQ9YKtq3AhElYw6vA/formResponse');
      form.setAttribute('target', iframeId);
      form.style.position = 'absolute';
      form.style.visibility = 'hidden';
      form.style.height = '1px';
      form.style.transform = 'translateX(-1000px)';
      form.innerHTML = `
        <input type="text" name="entry.1484334020" value="${email}">
        <input type="text" name="entry.416914406" value="${courseName}">
        <input type="text" name="entry.1891564576" value="${name}">
        <input type="text" name="entry.893135435" value="${courseID}">
        <input type="text" name="entry.1255694816" value="${answerText}">
      `;
      

      document.body.append(form);

      iframe.onload = function() {
        resolve(undefined);
      };

      form.submit();

    }));
  }

  submitQuizz = async ({ courseName, courseID, questionAndAnswer, email, name } : ISubmitFormQuiz) => {
    return VarHelper.erria(async () => {
      const url = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSe6rYvRD2Hez0rrf4hXc1SuhGEgXq5Y-vQ9YKtq3AhElYw6vA/formResponse';
      let answerText = '';
      questionAndAnswer.forEach(qa => {
        answerText += `[${qa.a}]: ${qa.q}\n`;
      });
      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': "application/x-www-form-urlencoded;charset=utf-8"
        },
        body: `entry.1484334020=${encodeURI(email)}&entry.416914406=${encodeURI(courseName)}&entry.1891564576=${encodeURI(name)}&entry.893135435=${courseID}&entry.1255694816=${encodeURI(answerText)}`,
      })
    });
  }

  submitQuizzAWS = async ({ courseName, courseID, questionAndAnswer, email, name } : ISubmitFormQuiz) => {
    return VarHelper.erria(async () => {
      // const url = 'https://342546l17j.execute-api.us-east-1.amazonaws.com/prod/payment-acad-2';
      const url = 'https://342546l17j.execute-api.us-east-1.amazonaws.com/default/payment-acad-2';
      let answerText = '';
      questionAndAnswer.forEach(qa => {
        answerText += `[${qa.a}]: ${qa.q}\n`;
      });
      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': "application/x-www-form-urlencoded;charset=utf-8"
        },
        body: JSON.stringify({
          data : {
            courseName, courseID, questionAndAnswer, email, name, answerText,
          },
        }),
      })
    });
  }

  checkScore = ({ courseName, courseID, questionAndAnswer } : ISubmitQuizz) => {
    const qData = this.state.allQuestions.filter(val => val.name === courseName);
    if (qData.length === 0) {
      console.warn('qData not found');
      Sentry.captureException(JSON.parse(JSON.stringify({
        message: 'qData not found',
        courseID,
        courseName,
        allQuestions: this.state.allQuestions,
      })));
      return 0;
    }
    let score = 0;
    const errorLog = [];
    questionAndAnswer.forEach(val => {
      const findQ = qData.find(q => q.question === val.q);
      if (!findQ) {
        Sentry.captureException(
          JSON.parse(JSON.stringify({
            message: 'Cant not find question',
            courseID,
            questionAndAnswer,
            courseName,
            qa: val,
            qData,
          }))
        );
        return;
      }
      // const toBeHashed = (val.q + val.a.sort().join('')).replace(/[^a-zA-Z1-6 ]/g, "");
      // const hashed = md5(toBeHashed);
      // console.log('toBeHashed', toBeHashed)
      // console.log('hashed', hashed, val.q, val.a);
      // const isCorrect = hashed === findQ.correctAnswer;
      const  isCorrect = val.a.sort().join('') === findQ.correct.trim().split(',').map(val => val.trim()).sort().join('');
      // console.log('isCorrect', isCorrect, findQ.correct);
      if (isCorrect) score += 1;
      else {
        errorLog.push({ isCorrect: false, qa: findQ, answers: val.a });
      }
    });
    if (score === 0) {
      console.log('Score = 0', errorLog);
      Sentry.captureException(
        JSON.parse(JSON.stringify({
          message: 'Score = 0',
          score: 0,
          courseID,
          questionAndAnswer,
          courseName,
          errorLog,
          qData,
        }))
      );
    }
    return score;
  }

  getMyActiveCourses = (email) => {
    if (!email) return [];
    const myUser = this.state.allUsers.find(val => val.email.toLowerCase() === email.toLowerCase());
    if (!myUser) return [];
    const { activeCourses } = myUser;
    if (!activeCourses) return this.state.courses;
    const arr = activeCourses.split(',').map(val => val.trim());
    const isPaymentsFundamentalsRequired = arr.includes('Payments fundamentals');
    return this.state.courses.map(val => {
      return {
        ...val,
        isRequired: val.category === 'Payments fundamentals' ? isPaymentsFundamentalsRequired : arr.includes(val.name)
      };
    });
  }

  getProgressInfo = (emailInput) => {
    const email = emailInput || '';
    const myAnswers : { [id : string]: Array<IGSheetUserAnswers> } = {};
    this.state.allUserAnswers.filter(val => val.email.toLowerCase() === email.toLowerCase()).forEach(val => {
      if (!myAnswers[val.courseID]) myAnswers[val.courseID] = [val];
      else myAnswers[val.courseID].push(val);
    });
    const myUser = this.state.allUsers.find(val => val.email.toLowerCase() === email.toLowerCase());
    // console.log('myUser', myUser);
    if (!myUser) return {};
    const activeCourses = myUser.activeCourses || '';
    const arr = activeCourses.split(',').map(val => val.trim());
    const isPaymentsFundamentalsRequired = arr.includes('Payments fundamentals');

    let requiredComplete = 0;
    let requiredIncomplete = 0;
    let allComplete = 0;
    let allIncomplete = 0;
    const listCompleted = [];
    const listCompletedReport = [];
    const courses = this.getMyActiveCourses(email);

    const isPaymentsFundamentalsCounted = {
      requiredComplete: false,
      requiredIncomplete: false,
      allComplete: false,
      allIncomplete: false,
    };

    const isPaymentFundamentalsCategoryCompleted = (() => {
      const countCategoryCourse = courses.filter(c => c.category === 'Payments fundamentals');
      return countCategoryCourse.length === countCategoryCourse.filter(c => !!myAnswers[c.courseID]).length;
    })();

    if (isPaymentFundamentalsCategoryCompleted) {
      listCompletedReport.push('Payments fundamentals');
    }

    courses.forEach(val => {
      if (val.activeIcon === 'Coming soon') return;
      if (val.category === 'Payments fundamentals') {
        if (myAnswers[val.courseID]) listCompleted.push(val.courseID);
        if (isPaymentFundamentalsCategoryCompleted) {
          if (!isPaymentsFundamentalsCounted.allComplete) {
            allComplete += 1;
            isPaymentsFundamentalsCounted.allComplete = true
          }
          if (isPaymentsFundamentalsRequired) {
            if (!isPaymentsFundamentalsCounted.requiredComplete) {
              requiredComplete += 1;
              isPaymentsFundamentalsCounted.requiredComplete = true;
            }
          }
        } else {
          if (!isPaymentsFundamentalsCounted.allIncomplete) {
            allIncomplete += 1;
            isPaymentsFundamentalsCounted.allIncomplete = true
          }
          if (isPaymentsFundamentalsRequired) {
            if (!isPaymentsFundamentalsCounted.requiredIncomplete) {
              requiredIncomplete += 1;
              isPaymentsFundamentalsCounted.requiredIncomplete = true;
            }
          }
        }
      } else {

        // THE REST OF THE COURSES

        if (myAnswers[val.courseID]) {
          listCompleted.push(val.courseID);
          listCompletedReport.push(val.courseID);
          allComplete += 1;
          if (val.isRequired) requiredComplete += 1;
        } else {
          allIncomplete += 1;
          if (val.isRequired) requiredIncomplete += 1;
        }


      }

      
    });

    return {
      requiredComplete,
      requiredIncomplete,
      allComplete,
      allIncomplete,
      listCompleted,
      listCompletedReport,
      listRequiredCourses: activeCourses,
    }
  }

}

export default new GSheet();
