import React, { useState, useEffect, useMemo } from 'react';
import { useWindowDimensions, Platform, Linking } from 'react-native';
import { MainContentContainer, Input, Button } from 'components';
import { Col, Row, Text, useDynamicResponsiveValue, Img, Scroll } from 'react-quick-style-components';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { ICON, IMG } from 'assets';
import { COLOR, fSize, SCREEN_NAME } from 'const';
import { saveAs } from 'file-saver';
const md5 = require('md5');

import _ from 'lodash';
import { ICourseNew } from 'type';

let XLSX;

const ReportRow = (val) => {
  const [showCourse, setShowCourse] = useState(false);
  return (
    <Col onHoverStyle={{ backgroundColor: 'rgba(0,0,0,0.1)' }}>
      <Row padding5 >
        <Col flex1>
          <Text>{val.user.Email}</Text>
        </Col>
        <Col flex1 marginLeft20>
          <Text>{String(val.data.allComplete)}</Text>
        </Col>
        <Col flex1>
          <Text>{String(val.data.requiredComplete)}</Text>
        </Col>
        <Col flex1>
          <Text>{String(val.data.allIncomplete)}</Text>
        </Col>
        <Col flex1>
          <Text>{String(val.data.requiredIncomplete)}</Text>
        </Col>
        {Boolean(val.data.listCompletedReport.length > 0) ? (
          <Col flex1 onPress={() => setShowCourse(!showCourse)} alignItems="flex-end">
            <Text fontSize={12}>{showCourse ? 'Hide' : 'View'} all completed courses</Text>
          </Col>
        ) : (
          <Col flex1 />
        )}
      </Row>
      {showCourse && (
        <Col alignItems="flex-end">
          {/* {console.log(val.data.listCompleted)} */}
          {val.data.listCompletedReport.map((c, j) => (
            <Text key={'listCompleted-' + j}>
              {!!val.courses.find(val => val['Course ID'] === c) ? val.courses.find(val => val['Course ID'] === c).Name : typeof c === 'string' ? c : ''}
            </Text>
          ))}
        </Col>
      )}
    </Col>
  )
}

const Report = () => {
  const { navigation, route, goBackOrTo, reset } = useNavFunc();
  const [{ user, ready }, uApi] = Store.User.createStore();
  const [doNotHavePermission, setDoNotHavePermission] = useState(false);
  const [loading, setLoading] = useState(true);
  const [{ courses }, cApi] = Store.Course.createStore();
  const [reportData, setReportData] = useState([]);
  
  const getData = async () => {
    const [res] = await cApi.getAllCourse();
    if (!res && !res.data) return;
    const allCourses : Array<ICourseNew> = res.data;
    const [res2] = await cApi.getReportData();
    if (res2 && !res2.success) {
      setDoNotHavePermission(true);
    } else if (res2 && res2.data) {
      const { answers, requiredCourses } = res2.data;
      const data = [];
      requiredCourses.forEach((val, i) => {
        const requiredCoursesForThisUser = val['Required Courses'].split(', ').map(t => t.trim());
        data.push({
          user: val,
          data: cApi.getMyProgressInfo(
            answers.filter(a => a.Email === val.Email),
            requiredCoursesForThisUser,
          ),
        });
      })
      setReportData(data);
    }
    setLoading(false);
  };
  
  useEffect(() => {
    if (!user?.email && ready) {
      navigation.navigate(SCREEN_NAME.SignUp);
    } else if (!!user?.email && ready) {
      getData();
    }
  }, [user, ready]);

  useEffect(() => {
    if (!XLSX) {
      import('xlsx').then(xlsx => {
        XLSX = xlsx;
      });
    }
  }, []);

  // const userData = useMemo(() => {
  //   if (!ready || !dataReady) return [];
  //   const uniqUsers = _.uniqBy(allUsers, 'email');
  //   return uniqUsers.filter(val => !!val.email).map(val => ({
  //     user: val,
  //     data: Store.GSheet.getProgressInfo(val.email),
  //   }));
  // }, [ready, dataReady, allUsers, allQuestions, courses]);

  // useEffect(() => {
  //   // check duplicate
  //   let hasDuplicate = false;
  //   userData.forEach(({ user, data }) => {

  //     if (data.listCompleted.length === 0) return;
  //     const toFindDuplicates = arry => arry.filter((item, index) => arry.indexOf(item) !== index)
  //     const duplicateElementa = toFindDuplicates(data.listCompleted);
  //     if (duplicateElementa.length > 0) {
  //       hasDuplicate = true;
  //       console.log(`user ${user.email} has duplicate data:`, duplicateElementa);
  //       console.log(`list completed: `, data.listCompleted);
  //     }
  //   });
  //   console.log('hasDuplicate', hasDuplicate, userData.length);
  // }, [userData]);

  if (!ready || loading) return (
    <Col flex1>
      <Col absoluteFill>
        <Img source={{ uri: IMG.COURSE_DETAIL_BG }} style={{ width: '100%', height: '100%' }} />
      </Col>
      <Col absoluteFill middle>
        <Text>loading..</Text>
      </Col>
    </Col>
  );

  if (doNotHavePermission) return (
    <Col flex1>
      <Col absoluteFill>
        <Img source={{ uri: IMG.COURSE_DETAIL_BG }} style={{ width: '100%', height: '100%' }} />
      </Col>
      <Col absoluteFill middle>
        <Text>In order to access this page, you must be an admin!</Text>
      </Col>
    </Col>
  );

  const exportExcel = async () => {

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(reportData.map(val => ({
      'User Email': val.user.Email,
      'All Complete': val.data.allComplete,
      'Required Complete': val.data.requiredComplete,
      'All Incomplete': val.data.allIncomplete,
      'Required Incomplete': val.data.requiredIncomplete,
      'List Completed Courses': (() => {
        if (val.data.listCompletedReport.length === 0) return '';
        const listName = val.data.listCompletedReport.map(c => {
          return !!courses.find(val => val['Course ID'] === c) ? courses.find(val => val['Course ID'] === c).Name : typeof c === 'string' ? c : '';
        });
        return listName.join(', ');
      })(),
      'List Required Courses': val.data.listRequiredCourses.join(', '),
    })));
    XLSX.utils.book_append_sheet(wb, ws, "book");
    var wbout = XLSX.write(wb, {bookType:'xlsx', type:'array'});
    saveAs(new Blob([wbout],{type:"application/octet-stream"}), `reports-${new Date().getTime()}.xlsx`);
  };

  console.log('reportData', reportData)

  return (
    <Col flex1>
      <Scroll flex1 padding20>
        <Row marginBottom10>
          <Col backgroundColor="black" borderRadius8 paddingHorizontal20 paddingVertical10 onPress={exportExcel}>
            <Text colorWhite>Export as Excel</Text>
          </Col>
        </Row>
        <Row>
          <Col flex1>
            <Text bold>User email</Text>
          </Col>
          <Col flex1 marginLeft20>
            <Text bold>All Complete</Text>
          </Col>
          <Col flex1>
            <Text bold>Required Complete</Text>
          </Col>
          <Col flex1>
            <Text bold>All Incomplete</Text>
          </Col>
          <Col flex1>
            <Text bold>Required Incomplete</Text>
          </Col>
          <Col flex1></Col>
        </Row>
        {reportData.map((val, i) => (
          <ReportRow courses={courses} {...val} key={'report-row'+i} />
        ))}
      </Scroll>
    </Col>
  )
};

export default Report;