import React, { useState, useEffect, useRef } from 'react';
import { Col, Img, Row, Text, useDynamicResponsiveValue, Scroll } from 'react-quick-style-components';
import { ICON, IMG } from 'assets';
import { useWindowDimensions, Linking } from 'react-native';
import { MainContentContainer, Input, Button, RatioCol, DashboardScore, CourseItemNew, CategoryItemNew } from 'components';
import Store from 'store';
import Svg, { Text as SvgText, TSpan, G, } from "react-native-svg"
import { fSize, SCREEN_NAME } from 'const';
import { useNavFunc } from 'navigation';

import _ from 'lodash';
import { ICourse, ICourseNew } from 'type';
import { VarHelper } from 'helpers';

interface ICate {
  name: string,
  isCategoryCompleted: boolean,
  isRequired: boolean,
  subCates: Array<{
    name: string,
    data: Array<ICourseNew>,
  }>
}

const Dashboard = (props) => {
  const [{ user, ready }] = Store.User.createStore();
  const [{ courses : courses2, requiredCourses, myAnswers }, cApi] = Store.Course.createStore();
  const [progressInfo, setProgressInfo] = useState(cApi.getMyProgressInfo());

  const { navigation, reset } = useNavFunc();
  const { width } = useWindowDimensions();
  const rV = useDynamicResponsiveValue();

  useEffect(() => {
    if (!user?.email && ready) {
      reset(SCREEN_NAME.SignUp);
    }
    if (ready && !!user?.email) {
      cApi.getAllCourse();
      cApi.getMyRequiredCourses();
      cApi.getMyAnswers();
    }
  }, [user, ready]);

  useEffect(() => {
    setProgressInfo(cApi.getMyProgressInfo());
  }, [courses2, requiredCourses, myAnswers]);
  
  const isCompleted = (courseID) =>  !!myAnswers.find(val => val['Course ID'] === courseID);

  const listCate: Array<ICate> = (() => {
    if (!courses2) return [];
    const cates: Array<ICate> = [];
    courses2.forEach(val => {
      const subCate = val['Sub Category'] || 'untitled';
      const findCateIndex = cates.findIndex(c => c.name === val['Category']);
      if (findCateIndex === -1) {
        cates.push({
          name: val['Category'],
          isCategoryCompleted: (() => {
            const countCategoryCourse = courses2.filter(c => c['Category'] === val['Category']);
            return countCategoryCourse.length === countCategoryCourse.filter(c => isCompleted(c['Course ID'])).length;
          })(),
          isRequired: (() => {
            if (requiredCourses.includes(val.Category)) return true;
            return false;
          })(),
          subCates: [
            {
              name: subCate,
              data: [val]
            }
          ]
        })
      } else {
        const findSubCateIndex = cates[findCateIndex].subCates.findIndex(sc => sc.name === subCate);
        if (findSubCateIndex === -1) {
          cates[findCateIndex].subCates.push({
            name: subCate,
            data: [val],
          })
        } else {
          cates[findCateIndex].subCates[findSubCateIndex].data.push(val);
        }
      }
    })
    return cates;
  })();

  const marginBottom = rV({ xs: 20, lg: 40, xl: 50, xxl: 68 });

  // console.log('requiredCourses', requiredCourses);
  // console.log('listCate', listCate);

  return (
    <Col flex1>
      <Col absoluteFill>
        <Scroll style={{ flex: 1 }}>
          {/* HEADER */}
          <Col paddingTop={826 / 1920 * 100 + '%'} width100p>
            <Col absoluteFill>
              <Img source={IMG.DASHBOARD_BG} style={{ width: '100%', height: '100%' }} resizeMode="cover" />
            </Col>
            <Col absoluteFill backgroundColor="rgba(0,0,0,0.3)">
              <MainContentContainer flex1 paddingTop30 alignSelf="center">
                <Col onPress={() => navigation.navigate(SCREEN_NAME.SignUp)}>
                  <ICON.AmadeusIcon style={{ marginLeft: rV({ xs: 10, lg: 0 }) }} />
                </Col>
                <Row flex1 padding10>
                  <RatioCol ratio={898 / 284} width={Math.min(898, width * 826 / 1920)}>
                    <Svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100%"
                      height="100%"
                      viewBox="0 0 899 284"
                      {...props}
                    >
                      <SvgText
                        transform="translate(0 82)"
                        fill="#fff"
                        fontSize={90}
                        fontFamily="Amadeus-Light, Amadeus"
                        fontWeight={300}
                      >
                        <TSpan x={0} y={0}>
                          {"Enhance your "}
                        </TSpan>
                        <TSpan x={0} y={90}>
                          {"knowledge and expertise "}
                        </TSpan>
                        <TSpan x={0} y={180}>
                          {"of travel payments"}
                        </TSpan>
                      </SvgText>
                    </Svg>
                  </RatioCol>
                </Row>
                <DashboardScore {...progressInfo} />
              </MainContentContainer>
            </Col>
          </Col>
          {/* COURSES */}
          <Col>
            <MainContentContainer flex1 padding10 paddingVertical={marginBottom} alignSelf="center">
              {listCate.map((val, i) => {
                return (
                  <Col key={'cate-' + i}>
                    <Text light fontSize={fSize(rV, 70)} marginBottom={marginBottom} colorMain>{val.name}</Text>
                    {Boolean(i === 0) && (
                      <RatioCol ratio={369 / 24} width={Math.min(369, width * 0.3)} marginBottom={marginBottom}>
                        <ICON.CourseStatusColorRow width="100%" height="100%" />
                      </RatioCol>
                    )}
                    {val.subCates.length === 1 && val.subCates[0].name === 'untitled' ? (
                      <Col marginBottom={marginBottom}>
                        <CategoryItemNew
                          name={val.name}
                          courses={val.subCates[0].data}
                          isCompleted={val.isCategoryCompleted}
                          isRequired={val.isRequired}
                          progressInfo={progressInfo}
                        />
                      </Col>
                    ) : val.subCates.map((sc, scI) => {
                      return (
                        <Col marginBottom={marginBottom} key={'sub-cate-' + i + scI}>
                          {Boolean(sc.name !== 'untitled') && <Text bold fontSize={fSize(rV, 30)} marginBottom20>{sc.name}</Text>}
                          {/* <Row flexWrap="wrap" marginBottom={marginBottom}>
                            {sc.data.map()}
                          </Row> */}
                          <Row
                            left={-10}
                            right={-10}
                            stretch
                            responsiveLayout={{
                              xs: `100%`,
                              md: `50%`,
                              xl: `${100 / 3}%`,
                              xxl: `${100 / 3}%`,
                            }}
                          >
                            {sc.data.map((val, i) => (
                              <Col key={'item' + i}>
                                <Col flex1 margin10>
                                  <CourseItemNew
                                    key={'course-'+val['Course ID']}
                                    {...val}
                                    onPress={VarHelper.imgApiStringifyGetAll(val['Icons']).legnth === 0 ? undefined : () => {
                                      navigation.navigate(SCREEN_NAME.CourseDetail, { id: val['Course ID'], slug: VarHelper.toSlug(val['Name']) })
                                    }}
                                    isCompleted={progressInfo?.listCompleted?.includes(val['Course ID'])}
                                    isRequired={requiredCourses.includes(val.Name)}
                                  />
                                </Col>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      );
                    })}
                  </Col>
                );
              })}
            </MainContentContainer>
          </Col>
        </Scroll>
      </Col>
    </Col>
  );
};

export default Dashboard;